import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ElementRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatDialog, Sort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../shared/services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { FullLayoutComponent } from '../layouts/full-layout.component'
import * as _ from 'underscore';
import { PagerService } from '../shared/pagenation/index'
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupUploadComponent } from '../shared/component/popup-upload/popup-upload.component';
import { ToastrService } from 'ngx-toastr';
import { hasAlignedHourOffset } from 'ngx-bootstrap/chronos/units/offset';
import { ArrayPairPipe } from '../shared/pipes/pipes.module';
import { DatePipe } from '@angular/Common';
import { DeletePopupComponent } from '../shared/delete-popup/delete-popup.component';
import { SettingComponent } from '../setting/setting.component';


@Component({
  selector: 'app-xperts-page',
  templateUrl: './xperts-page.component.html',
  styleUrls: ['./xperts-page.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
  providers: [ArrayPairPipe, DatePipe]
})
export class XpertsPageComponent implements OnInit {
  showCreateBtn: boolean = true;
  imagePath = 'assets/img/avatars/User.jpg';
  serchIcon: string = "fa fa-search pointer fa-1x";
  loading: boolean;
  model: any = {};
  modelFilters: any = [];
  modelDataFilters: any = [];
  modelMultiSelect: any = [];
  pageColor: any;
  page: any = {};
  pageFilters: any = [];
  pageFilterRef: any = [];
  pageResult: any = [];
  //displayedColumns = [];
  pageData: any = {};
  pageFiltersData: any = [];
  pageResultData: Element[] = [];
  pageGridResult: any = [];
  pageGridBindingData: any = [];

  postXpertsResultData: any = {};
  divColPerRow: string = '';

  isActive = 1;
  pageRouterLink: string;
  pageIndex: number = 1;
  DeleteItem: any;
  //pageMode: number = 0;
  pagelength = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  userData: any = {};
  NewDate: Date = new Date();
  objUser: any;
  currentUser: any;
  admin: number;
  location: Location;
  @Output() pageMode = new EventEmitter<number>();
  @Output() pageEditData = new EventEmitter<any>();
  @Input() create: boolean;
  isCollapsed: boolean = false;
  keywords: string = "";
  checkControl9: boolean = false;
  disableSearchInput: boolean = false;
  @ViewChild('Advdropdown') Advdropdown: ElementRef;
  errorMsg: string = "";
  AccessPermission: any = { Delete: false, Update: false, Insert: false, View: false };
  user$: any;
  public dangerModal;
  deleteColor: boolean = false;
  url: string;
  loginDetails: any;
  array: any = {};
  constructor(public dialog: MatDialog, private _eref: ElementRef, private router: Router,
    public iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public dtpipe: DatePipe,
    private authenticationService: AuthenticationService, private layout: FullLayoutComponent,
    private pagerService: PagerService, public toastr: ToastrService, private route: ActivatedRoute) {
    this.url = window.location.href;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));    // console.log(this.currentUser.isadmin,'admin');
    this.loginDetails = JSON.parse(sessionStorage.getItem('LoginDetials'));
    if (sessionStorage.getItem('selectedMenu') === null || JSON.parse(sessionStorage.getItem('selectedMenu')) == undefined) {
      this.router.navigate(['/dashboard']);
    }
    iconRegistry.addSvgIcon('Advsearch_ico', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg_ico/arrow-down.svg'));
    iconRegistry.addSvgIcon('keyboard_ico', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg_ico/keyboard1.svg'));
    this.route.params.subscribe(params => this.user$ = params.id);
  }

  onClickedOutside(e: any) {
    //console.log(e.target.className);
    if ((e.target.className.match(/mat-calendar/g) != null) || (e.target.className.match(/ng-option/g) != null)) {
      //console.log('don\'t close');
    } else {
      this.isCollapsed = false;
    }
  }
  onClick(event: any) {
  }
  //Autoslash updated by venkat 25/12/2018
  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0))
        && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  };

  autoSlash(event: any) {
    let input = event.target.value;
    if (/\D\/$/.test(input))
      input = input.substr(0, input.length - 3);
    let values = input.split('/').map(v => {
      return v.replace(/\D/g, '');
    });

    if (values[0]) values[0] = this.checkValue(values[0], 12);

    if (values[1]) values[1] = this.checkValue(values[1], 31);

    let output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + '/' : v;
    });
    event.target.value = output.join('').substr(0, 14);
  }

  changePage(e: any) {
    this.pageIndex = (e.pageIndex + 1);
    this.pageSize = e.pageSize;
    this.pagelength = e.length;
    this.search();
  }
  CreateDate(days) {
    let dt = new Date();
    dt.setDate(dt.getDate() + days);
    return dt;
  }


  ngOnInit() {
    this.model.gridCol = "col-sm-12 col-md-12 col-lg-12 py-2";
    this.loading = true;
    if (this.create != undefined) {
      this.showCreateBtn = this.create;
    }
    this.pageData = JSON.parse(sessionStorage.getItem('selectedMenu'));
    this.AccessPermission.Update = this.pageData.Update;
    this.AccessPermission.Insert = this.pageData.Insert;
    this.AccessPermission.Delete = this.pageData.Delete;
    this.AccessPermission.Copy = this.pageData.Copy;
    this.AccessPermission.Upload = this.pageData.Upload;

    this.AccessPermission.View = this.pageData.View;
    this.userData = JSON.parse(sessionStorage.getItem('currentUser'));
    this.errorMsg = '';
    this.authenticationService.PostXpertsPage(this.pageData).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == '111') {
          this.page.PageGuid = result.PageGuid;
          this.page.PageName = result.PageName;
          this.page.PageType = result.PageType;
          this.page.RowCnt = result.RowCnt;
          this.page.PrintViewEnabled = result.PrintViewEnabled;
          this.page.ExportEnabled = result.ExportEnabled;
          this.page.SendEmailEnabled = result.SendEmailEnabled;
          this.page.ColsPerRow = result.ColsPerRow;
          this.page.DefaultSort = result.DefaultSort;
          this.page.ExtraOptions = result.ExtraOptions;
          this.page.IsRowSelectRequired = result.IsRowSelectRequired;
          this.page.UploadEnabled = result.UploadEnabled;
          this.page.UploadTemplate = result.UploadTemplate;
          this.page.PrintviewColumnEnabled = result.PrintviewColumnEnabled;
          if (this.page.PrintviewColumnEnabled == '1' && this.page.PrintviewColumnEnabled != "") {
            this.page.PrintviewColumnConfig = JSON.parse(result.PrintviewColumnConfig);
          }
          let ExistFilter = sessionStorage.getItem('_crmFilters');
          if (ExistFilter != null) {
            let a = JSON.parse(ExistFilter);
            if (a.ID == this.page.PageGuid)
              ExistFilter = a.DATA;
            else
              ExistFilter = null;
          }
          /*Applying Page Data to storage*/
          sessionStorage.setItem('xpertsPageData', JSON.stringify(this.page));

          this.pageFilters = result.Filters;
          this.pageResult = result.Result;
          for (let color of this.pageResult) {
            if (color.CellColor) {
              this.pageColor = JSON.parse(color.CellColor);
            }
          }
          this.divColPerRow = "col-4";
          var arrLength = this.pageFilters.length;
          var tempPageFilters = [];
          this.objUser = JSON.parse(sessionStorage.getItem('currentUser'));
          this.pageFilters.forEach((element, index) => {
            if (element.FilterRef != '' && element.FilterRef != null) {
              tempPageFilters.push({ idx: index, ref: element.FilterRef });
            }
            else {
              tempPageFilters.push({ idx: index, ref: element.FilterRef });
            }
            if ((element.ControlType == '1') && (element.DefaultValue == '{"Session": "IVRNumber"}') && (this.user$ == 2)) {
              this.modelDataFilters.push(sessionStorage.getItem('IVRnumber'));
              setTimeout(() => this.search(), 3000);
            }
            else if (element.ControlType == '7') {
              this.modelFilters.push(element.FilterName + ':');
              this.modelDataFilters.push((ExistFilter != null) ? ExistFilter[element.FilterName] : []);
            } else if ((element.ControlType == '0') && (element.FilterType == '9')) {
              let reportnumber = location.hash.split('/')
              this.modelDataFilters.push(reportnumber[reportnumber.length - 1]);
            }
            else {
              if (element.ControlType == '9')
                this.checkControl9 = true;
              this.modelFilters.push(element.FilterName + ':');
              if (element.ControlType == '2') {
                if (ExistFilter != null) {
                  this.modelDataFilters.push(ExistFilter[element.FilterName]);
                } else {
                  if (element.DefaultValue == 'WAID')
                    this.modelDataFilters.push(this.userData.WAIDGUID);
                  else
                    this.modelDataFilters.push(null);
                }
              } else if (element.ControlType == '3') {
                if (ExistFilter != null) {
                  this.modelDataFilters.push(ExistFilter[element.FilterName]);
                } else {
                  if ((element.DefaultValue != '') && (element.DefaultValue != null) && (!isNaN(element.DefaultValue))) {
                    this.modelDataFilters.push(this.CreateDate(parseInt(element.DefaultValue)));
                  } else
                    this.modelDataFilters.push(null);
                }
              }
              else
                this.modelDataFilters.push((ExistFilter != null) ? ExistFilter[element.FilterName] : '');
            }

          });

          tempPageFilters.forEach(element => {
            if (element.ref != null && element.ref != "") {
              this.authenticationService.GetDropDownList(element.ref).subscribe(result => {
                if (result !== null) {


                  this.pageFilters[element.idx]['ReferenceData'] = result.Dropdownlist;

                  this.pageFilters[element.idx]['ReferenceSelectData'] = result.Dropdownlist;

                }
              })
            }
          });

        } else {
          if (result.Response._code == '999') {
            this.errorMsg = "Unable to process for your request";
          }

        }
        this.loading = false;
      }
      else {
        this.loading = false;
      }
    });
  }
  //filterForm = new FormControl('', [Validators.required, Validators.email]);
  getErrorMessage() {
    // return this.filterForm.hasError('required') ? 'You must enter a value' :
    //   this.filterForm.hasError('email') ? 'Not a valid email' :
    //     '';
  }
  selectChange(id, data, index) {
    this.modelDataFilters[index] = data;
  }
  multiChange(id, data, index) {
    if (data.length > 0) {
      this.modelDataFilters[index] = data.toString();
    }
    else {
      this.modelDataFilters[index] = null;
    }
  }
  filterBindKeyVal(key, val) {
    if (val == '0')
      return key + ':0';
    else
      return key + ':' + val;
  }
  Advsearch() {
    // console.log(this.keywords);
    this.search();
  }

  searchValid(f: NgForm) {
    console.log('hit1');

    if (f.valid == true) {
      console.log('hit2');
      this.pageIndex = 1
      console.log(this.pageIndex);
      this.search();
    } else {
      this.isCollapsed = true;
    }

  }
  search() {
    console.log(this.pageIndex);
    this.isCollapsed = false;
    this.loading = true;
    this.modelFilters = [];
    let localStr = {};
    this.pageFilters.forEach((element, index) => {

      if (element.ControlType == '7') {
        this.modelFilters.push(this.filterBindKeyVal(element.FilterName, this.modelDataFilters[index]));
        localStr[element.FilterName] = this.modelDataFilters[index];
      } else if (element.ControlType == '3') {
        if (typeof (this.modelDataFilters[index]) != 'string' && this.modelDataFilters[index] != null) {
          localStr[element.FilterName] = this.authenticationService.formatDate(this.modelDataFilters[index]);
          this.modelFilters.push(this.filterBindKeyVal(element.FilterName, this.authenticationService.formatDate(this.modelDataFilters[index])));
        } else {
          localStr[element.FilterName] = this.modelDataFilters[index];
          this.modelFilters.push(this.filterBindKeyVal(element.FilterName, this.modelDataFilters[index]));
        }
      }
      else {
        localStr[element.FilterName] = this.modelDataFilters[index];
        this.modelFilters.push(this.filterBindKeyVal(element.FilterName, this.modelDataFilters[index]));
      }
    }); sessionStorage.setItem('_crmFilters', JSON.stringify({ ID: this.page.PageGuid, DATA: localStr }));
    var pageResult = [];
    this.pageResult.forEach(element => {
      pageResult.push(element.ResultProcedureName);
    });

    console.log(this.pageIndex);
    this.postXpertsResultData = {
      "PageGUID": this.pageData.PageGUID,
      "Filter": this.modelFilters,
      "Results": pageResult,
      "PageSize": this.pageSize,
      "PageIndex": this.pageIndex,
      "OrderBy": null,
      "Direction": null
    };
    this.errorMsg = '';
    this.serchIcon = "fa fa-spinner fa-spin";
    this.authenticationService.PostXpertsResult(this.postXpertsResultData).subscribe(result => {
      this.loading = false;
      this.serchIcon = "fa fa-search pointer fa-1x";
      if (result.Response._code == '111') {
        this.pageGridBindingData = [];
        //var arrNavPosition = [];
        this.pagelength = result.pageInfo.TotalPageCount;
        this.pageSizeOptions.push(this.pagelength);
        this.pageGridResult = result.PageGridResult;


        result.PageGridResult.forEach((element, index) => {
          var arrGridData = [];
          var arrData = [];
          element.forEach((item, idx) => {
            arrData.push(item);
          });
          this.pageGridBindingData.push(Object.assign({}, { "data": arrData, "select": false }));
        });
      } else {
        if (result.Response._code == '333') {
          this.errorMsg = "Data Not Found";
          this.pageGridBindingData = [];
        }
        if (result.Response._code == '999') {
          this.errorMsg = "Unable to processed for your request";
        }
      }
    });

  }
  clear() {
    //under proces is for not to delete values of disabled fields
    this.pageFilters.forEach(element => {
      //predefining variable
      let getIndex;
      let valNoClear;
      //checking for disabled fields
      if (element.IsDisabled == 1 || (element.IsDisabled == 2 && this.currentUser.isadmin != 'True')) {
        let index = this.pageFilters.findIndex(array => array.FilterName == element.FilterName)
        getIndex = index;
        console.log(getIndex);
        valNoClear = this.modelDataFilters[index];

        this.modelDataFilters[index] = valNoClear
      }
      let idx = this.pageFilters.findIndex(array => array.FilterName == element.FilterName)
      this.modelDataFilters[idx] = null;
      this.modelDataFilters[getIndex] = valNoClear


    });
  }
  add() {
    this.pageMode.emit(1); // for add mode
  }

  // Copy feature implementated by Venkar on 21st dec 2018 
  //for copy mode
  copy(index) {
    //console.log(this.pageMode, 'pageMode');
    //console.log(this.pageEditData, 'this.pageEditData');
    this.pageMode.emit(3);
    this.pageEditData.emit(this.pageGridResult[index]);



  }


  edit(index) {
    this.pageMode.emit(2);
    this.pageEditData.emit(this.pageGridResult[index]);
    // for edit mode
  }

  view(index) {
    this.pageMode.emit(4);
    this.pageEditData.emit(this.pageGridResult[index]);
    // for edit mode
  }

  //bakya
  Print(index) {
    //console.log(this.pageGridResult[index][0]);
    //console.log(data,index,'data,index');
    window.open('Doc/FieldPrint.html?CallGUID=' + this.pageGridResult[index][0], '_blank');
  }
  printPreview(data, index, v) {
    console.log(data);
    PrintKey
    var PrintKey = data.PrintKey.trim().toLocaleLowerCase();


    var tem;


    if (PrintKey == 'print') {
      var id: any = 8;
      tem = [this.authenticationService.SalesApi, 'GetPRPrint', { 'PRGUID': this.pageGridResult[index][0] }];
    } else {
      id = 10;
      tem = [this.authenticationService.SalesApi, 'GetSalesInvoicePrint', { 'InvoiceGUID': this.pageGridResult[index][0] }];
    }
    let status = this.pageGridResult[index][id].toLowerCase();
    this.pageGridResult[index][id] = status;
    if ((this.pageGridResult[index][id] == 'approved' || (this.pageGridResult[index][id] == 'closed' && PrintKey == 'print')) || (this.pageGridResult[index][id] == 'received') || (PrintKey != 'print' && this.pageGridResult[index][id] == 'approved')) {
      this.authenticationService.CrmGetJsonService(tem[0], tem[1], tem[2]).subscribe(result => {
        if (result !== null) {
          if (result.Response._code == "111") {
            if (PrintKey == 'print') {
              window.open(data.PrintURL + '?PRGUID=' + this.pageGridResult[index][0], '_blank');
            } else {
              window.open(data.PrintURL + '?InvoiceGUID=' + this.pageGridResult[index][0], '_blank');
            }
          }
        }
      })
    } else {
      if (PrintKey == 'print') {
        this.toastr.warning('Only Approved and Closed Records Can be Printed...!');
      } else {
        // this.toastr.warning('Only Approved Records Can be Printed...!');
        this.toastr.warning('Only Approved & Received Records Can be Printed...!');
      }
    }
    // let tem = [this.authenticationService.masterApi, 'GetCallcenterWorkShopCallsDisplay', { 'CallsGUID': this.pageGridResult[index][0] }];
    // if (PrintKey == 'fieldcustomer' || PrintKey == 'fieldfranchise')
    //   tem[1] = 'GetCallcenterFieldCallsDisplay';
    // if (PrintKey == 'installation' || PrintKey == 'installationpv' || PrintKey == 'installationsolarandhp')
    //   tem[1] = 'GetCallcenterInstallationCallsDisplay';
    // this.authenticationService.CrmGetJsonService(tem[0], tem[1], tem[2]).subscribe(result => {
    //   if (result !== null) {
    //     if (result.Response._code == "111") {
    //       if (PrintKey == 'fieldcustomer' || PrintKey == 'fieldfranchise'){
    //         let d = result.CallcenterFieldCallsDisplay[0];
    //         console.log(d);
    //         d['PrintDate'] = this.dtpipe.transform(new Date(), 'dd/MM/yyyy hh:mm:a');
    //         d["PurchaseDate"] = this.dtpipe.transform(d["PurchaseDate"], 'dd/MM/yyyy');
    //         localStorage.setItem('_CRMCallsReport', JSON.stringify(d));
    //         //   window.open('Doc/delivery.html', '_blank');
    //         window.open(data.PrintURL, '_blank');
    //       }
    //       else if (PrintKey == 'installation' || PrintKey == 'installationpv' || PrintKey == 'installationsolarandhp') {
    //         let d = result.CallcenterWorkShopCallsDisplay[0]
    //         d['PrintDate'] = this.dtpipe.transform(new Date(), 'dd/MM/yyyy');
    //         d["PurchaseDate"] = this.dtpipe.transform(d["PurchaseDate"], 'dd/MM/yyyy');
    //         localStorage.setItem('_installetion', JSON.stringify(d));
    //         //window.open('Doc/installationnew.html', '_blank');
    //         window.open(data.PrintURL, '_blank');
    //       }
    //       else if (PrintKey == 'workshop') {
    //         let d = result.CallcenterWorkShopCallsDisplay[0];
    //         d['PrintDate'] = this.dtpipe.transform(new Date(), 'dd/MM/yyyy hh:mm:a');
    //         d["PurchaseDate"] = this.dtpipe.transform(d["PurchaseDate"], 'dd/MM/yyyy');
    //         localStorage.setItem('_CRMCallsReport', JSON.stringify(d));
    //         // window.open('Doc/workorder.html', '_blank');
    //         window.open(data.PrintURL, '_blank');
    //       }
    //       else {
    //         window.open(data.PrintURL, '_blank');
    //       }

    //     } else {

    //       this.toastr.error('Dev Testing', 'Error');
    //     }
    //   }
    // });

  }

  openDialog(index): void {
    this.deleteColor = true;
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.deleteColor = false;
      if (result === 'Yes') {
        this.DeleteItem = {
          'PageGUID': this.pageData.PageGUID,
          'RecordKey': this.pageGridResult[index][0]
        }
        //console.log(this.DeleteItem, 'this.DeleteItem');

        this.objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.pageGridResult.splice(index, 1);

        this.authenticationService.CrmPostJsonService(this.authenticationService.EiUtils, 'PostDelete', this.DeleteItem).subscribe(result => {

          if (result !== null) {
            if (result.Response._code == "111") {
              this.search();
              this.toastr.success(result.Response._Description, "Success");
            } else if (result.Response._code == "222") {
              this.toastr.warning(result.Response._Description, "Warning");
            } else {
              this.toastr.error(result.Response._Description, "Error");
            }
          }
        });
      }

      //console.log('The dialog was closed');

    });
  }
  /* 
    delete(index) {
      this.DeleteItem = {
        'PageGUID': this.pageData.PageGUID,
        'RecordKey': this.pageGridResult[index][0]
      }
      console.log(this.DeleteItem, 'this.DeleteItem');
  
      this.objUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.pageGridResult.splice(index, 1);
  
         this.authenticationService.CrmPostJsonService(this.authenticationService.EiUtils, 'PostDelete', this.DeleteItem).subscribe(result => {
           if (result !== null) {
             if (result.Response._code == "111") {
             
               this.toastr.success(result.Response._Description, "Success");
             }
             else if (result.Response._code == "222") {
             }
           }
         });
  
    } */

  delete(index) {

    if (confirm("Are you sure to delete?")) {
      this.DeleteItem = {
        'PageGUID': this.pageData.PageGUID,
        'RecordKey': this.pageGridResult[index][0]
      }
      //this.loading = true;
      var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.model.CreatedBy = objUser.userID;
      this.model.ModifiedBy = objUser.userID;
      //console.log('1');
      this.authenticationService.CrmPostJsonService(this.authenticationService.EiUtils, 'PostDelete', this.DeleteItem).subscribe(result => {
        // console.log('2');
        //this.loading = false;
        if (result !== null) {
          // console.log('3');
          if (result.Response._code == "111") {
            this.pageGridBindingData.splice(index, 1);
            this.search();
            // console.log('4 success');
            this.toastr.success(result.Response._Description, "Success");
          }
          else if (result.Response._code == "222") {
            this.toastr.warning(result.Response._Description, "Warning");

          }
          else {
            this.toastr.error(result.Response._Description, 'Error');
          }
        }
      });
    }


  }

  more(index) {
    this.pageMode.emit(4); // for more details
  }
  showFilters: boolean = false;
  filterToggle() {
    this.showFilters = !this.showFilters;
    if (this.showFilters)
      this.model.gridCol = "col-sm-10 col-md-10 col-lg-10 ";
    else
      this.model.gridCol = "col-sm-12 col-md-12 col-lg-12 ";
  }

  selectedAll: any;
  selectAll(eve) {
    this.pageGridBindingData.forEach(element => {
      element.select = eve.target.checked;
    });

  }
  select(eve) {
    //console.log(this.checkIfSelected());
  }
  checkIfSelected() {
    let a = this.pageGridBindingData.some(function (item: any) {
      return item.select == true;
    });
    return a;
  }

  ngOnDestroy() {
    //this.layout.showBreadcrumb = true;
  }

  UploadDialog(): void {
    let dialogRef = this.dialog.open(PopupUploadComponent, {
      width: '350px',
      data: { 'DownloadLink': '/' + this.page.UploadTemplate },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }

  //aligntype
  alignType(e: any) {
    if (e == '1')
      return 'left';
    else if (e == '2')
      return 'right';
    else if (e == '3')
      return 'center';
    else
      return '';
  }
  navigateToSetting() {
    console.log(this.page.PageGuid);
    this.router.navigate(['/Setting'], { queryParams: { pageGUID: this.page.PageGuid, url: this.url } });
  }


}