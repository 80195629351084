import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { AuthenticationService } from './shared/services';

import { environment } from '../environments/environment';
import { Title }  from '@angular/platform-browser';

@Component({
  //selector: 'body',
  //template: '<router-outlet></router-outlet>'

  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  

  constructor(private titleService: Title,private translate: TranslateService, private authenticationService: AuthenticationService) {
    //, private userIdle: UserIdleService
    translate.addLangs(['en', 'ta']);
    translate.setDefaultLang('en');
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ta/) ? browserLang : 'en');
    this.titleService.setTitle(this.authenticationService.env.StaticTitle);
  }
  ngOnInit() {
  
   // console.log(environment);
    var objUser;
    objUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if (objUser === null) {
      this.authenticationService.onLoggedout();
    }
    else {
      var activeFor = this.getMinutesDiff(objUser.loggedIn);
      var userTimeOut = 20; /*Default Time out in minutes*/
      
      // console.log("app component");
      // console.log(environment.sessionTimeOut !== null);
      if (environment.sessionTimeOut !== null)
        userTimeOut = parseInt(environment.sessionTimeOut);

      if (objUser.loggedIn === null || activeFor >= userTimeOut)
        this.authenticationService.onLoggedout();
      else
        this.sessionStart();
    }
  }
  restrictNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
   // const pattern = /^[a-zA-Z ]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      if (event.keyCode == 8 || event.keyCode == 9)
        console.log("backspace");
      else
        event.preventDefault();
    }
  }
  sessionStart() {
    var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    objUser.loggedIn = new Date();
    
    sessionStorage.setItem('currentUser', JSON.stringify(objUser));

    // //Start watching for user inactivity.
    // this.userIdle.startWatching();

    // // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe(count => {
    //   //console.log(count);
    // });

    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => {
    //   //console.log('Time is up!')      
    //   this.authenticationService.onLoggedout();
    // });
  }
  // stop() {
  //   this.userIdle.stopTimer();
  // }
  // stopWatching() {
  //   this.userIdle.stopWatching();
  // }
  // startWatching() {
  //   this.userIdle.startWatching();
  // }
  // restart() {
  //   this.userIdle.resetTimer();
  // }
  getMinutesDiff(startDate: Date) {
    var startDate = new Date(startDate);
    var endDate = new Date();
    var startMin = startDate.getMinutes();
    var endMin = endDate.getMinutes();

    if (startMin > endMin) {
      return startMin - endMin;
    }
    else {
      return endMin - startMin;
    }
  }
}
