import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterKey',
  pure: false
})
export class FilterkeyPipe implements PipeTransform {

  
  transform(data: any[], criteria: SortCriteria ): any {
    // console.log(data,criteria);
    if (!data || !criteria)
      return data;
  
    if(criteria.filter==undefined || criteria.filter==null || criteria.filter=='')
       return data;
   
    let result = data.filter(res=>{
      let b=-1;
      for(let i of criteria.fields){
        
        if(res[i]!=undefined && res[i]!=null){
        if(Array.isArray(res[i])==false){
          let a:string = res[i].toString().toLowerCase();
          let idx = a.indexOf(criteria.filter.toString().toLowerCase().trim())
          if(idx!=-1)
            b = idx;
          }else{
           
            res[i].filter(e=>{
              // console.log(e);
              for(let info in e){
                // console.log(e[info]);
                if(info!=null && info!=undefined){
                let idx = info.toString().toLowerCase().indexOf(criteria.filter.toString().toLowerCase().trim());
                // console.log(idx);
                if(idx!=-1)
                  b = idx;
                }
                // console.log(b);
              }
             
            });
          }
        }
      }
      // console.log(b);
      return b!=-1;
    });
      
      return result;

  }

}

export interface SortCriteria {
    filter: string;
    fields: any[];

}
