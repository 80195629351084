import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintance',
  templateUrl: './maintance.component.html',
  styleUrls: ['./maintance.component.scss']
})
export class MaintanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
