import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-popup-workarea',
  templateUrl: './popup-workarea.component.html',
  styleUrls: ['./popup-workarea.component.scss']
})
export class PopupWorkareaComponent {
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins'];
  workAreaName: any;
  constructor(public dialogRef: MatDialogRef<PopupWorkareaComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  changeSel(e){
    let idx=this.data.List.findIndex(array=>array.ID==e)
    console.log(this.data.List.findIndex(array=>array.ID==e));
    if(idx!=-1){
      this.workAreaName=this.data.List[idx].Value
      console.log(this.workAreaName);
      
    }
    this.dialogRef.close({Selected:e,name:this.workAreaName} );
    console.log(e);
    
  }  

}
