import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import {  NgForm } from '@angular/forms';
@Component({
  selector: 'app-purchase-pvupdate-popup',
  templateUrl: './purchase-pvupdate-popup.component.html',
  styleUrls: ['./purchase-pvupdate-popup.component.scss']
})
export class PurchasePvupdatePopupComponent implements OnInit {
model:any={};
  PRID: any;
  totalPV:any;
  GUID:any;
   updatebtn:boolean=false;
  ProgressSpinner:boolean=false;
  constructor(private toastr: ToastrService, private authService: AuthenticationService,public dialogRef: MatDialogRef<PurchasePvupdatePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("data==>",data)
  this.PRID=data.PRNO;
  this.totalPV=data.PRTotalPV;
  this.GUID=data.PRGUID
  console.log(" this.totalPV===>",this.totalPV)
  console.log("this.GUID==>",this.GUID)
   }
   ngform(){
    this.model={
        "PRGUID":this.GUID,
        "PRPV":"",
        "PRSV":""       
    }
   }
   
  ngOnInit() {

  }
  close(){
    this.dialogRef.close();
  }
  cancel(){
    this.ngform();
  }
  prsvAmount(){
    let total=Number(this.model.PRPV)+Number(this.model.PRSV)
    console.log("PRSV====>",total)
    if(total ==this.totalPV){
      console.log("total====>",total)
     this.updatebtn=true;
    }
    else{
       this.updatebtn=false;
    }
  }
  postForm(form:NgForm){
    console.log(" this.model==>", this.model)
    console.log("this.GUID,post;",this.GUID)
    this.model.PRGUID=this.GUID;
    this.ProgressSpinner=true;
    this.authService.CrmPostJsonService(this.authService.SalesApi, 'PostPurchasePVSVUpdate', this.model).subscribe(result => {
    this.ProgressSpinner=false;
     
      if(result!=null){
        if(result.Response._code=='111'){
          this.toastr.success(result.Response._Description);
          this.dialogRef.close('success');
        }else{
          this.toastr.error(result.Response._Description);
        }
      }
    })
  
  }
}
