import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-purchase-branch-change',
  templateUrl: './purchase-branch-change.component.html',
  styleUrls: ['./purchase-branch-change.component.scss']
})
export class PurchaseBranchChangeComponent implements OnInit {
  ProgressSpinner: boolean;
  branchList: any = [];
  branchId: any = [];
  outofstock: boolean = false;
  model: any = {}
  model1: any = {}
  icon: string = 'fa fa-save';
  constructor(private toastr: ToastrService, private authService: AuthenticationService, public dialogRef: MatDialogRef<PurchaseBranchChangeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.laodDropDown();
    this.ngForm();
  }
  laodDropDown() {
    this.authService.GetDropDownList('Location', '1').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.branchList = result.Dropdownlist;
          console.log("this.branchlist", this.branchList);
        }


      }
    });

  }
  branchlistid(ID) {
    console.log("this.id", ID)
    //  this.branchList.forEach(element => {
    //       this.branchId = element.ID
    //       console.log("branchid",this.branchId);
    //     });
    this.model1.TransferBranchGUID = ID
  }
  ngForm() {
    this.model = {
      "PRGUID": this.data,
      "BranchGUID": "",
      "Remarks": ""
    },
      this.model1 = {
        "PRGUID": this.data,
        "TransferBranchGUID": "",
      }
  }
  close() {
    this.dialogRef.close();
  }

  // postForm()
  postForm() {
    this.icon = 'fa fa-spinner fa-spin';
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.distributorAPi, 'GetCheckStockForTransfer', this.model1).subscribe(result => {
      this.ProgressSpinner = false;
      this.icon = 'fa fa-save'
      if (result != null) {
        if (result.Response._code == '111') {
          // this.icon = 'fa fa-spinner fa-spin';
          this.ProgressSpinner = true;
          this.outofstock = false;
          this.authService.CrmPostJsonService(this.authService.SalesApi, 'PostPRBranchTransfer', this.model).subscribe(result => {
            this.ProgressSpinner = false;
            if (result != null) {
              if (result.Response._code == '111') {
                this.toastr.success(result.Response._Description);
                this.dialogRef.close('success');
              }
              else {
                this.toastr.error(result.Response._Description);
              }
            }
          })
        }
        else{
          this.outofstock = true;
          this.toastr.error(result.Response._Description);
        }
      }
    })
  }
}
