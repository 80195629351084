import { Component, Inject ,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-distribuor-popup',
  templateUrl: './distribuor-popup.component.html',
  styleUrls: ['./distribuor-popup.component.scss']
})
export class DistribuorPopupComponent implements OnInit {
  ProgressSpinner: boolean;
  Reason:any;
  icon: string='fa fa-check';
  constructor(private toastr: ToastrService, private authService: AuthenticationService, public dialogRef: MatDialogRef<DistribuorPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    
  }
  close(){
    this.dialogRef.close();
  }
  deleteorRemove(type){
    this.icon='fa fa-spinner fa-spin';
    if(type=='delete'){    
    this.ProgressSpinner=true;
    this.authService.CrmPostJsonService(this.authService.distributorAPi, 'PostDeleteDistributor', {"DistGUID":this.data.GUID}).subscribe(res => {
    this.ProgressSpinner=false;
    this.icon='fa fa-check'
      let result:any=res;
      if(result!=null){
        if(result.Response._code=='111'){
          this.dialogRef.close();
          this.toastr.success(result.Response._Description);
        }else if(result.Response._code=='222') {
          this.toastr.warning('Distributor is active, please contact administrator');
        }else{
          this.toastr.warning(result.Response._Description);
        }
      }
    })
    }else{ 
    this.icon='fa fa-spinner fa-spin';

    this.ProgressSpinner=true;
    this.authService.CrmPostJsonService(this.authService.distributorAPi, 'PostRemoveDistributor', {"DistGUID":this.data.GUID} ).subscribe(res => {
    this.ProgressSpinner=false;     
    this.icon='fa fa-check'
      let result:any=res;
      if(result!=null){
        if(result.Response._code=='111'){
          this.dialogRef.close();
          this.toastr.success(result.Response._Description);
        }else if(result.Response._code=='222') {
          this.toastr.warning('Distributor is active, please contact administrator');
        }else{
          this.toastr.warning(result.Response._Description);
        }
      }
    })
    }
  }
  postForm(){
    this.icon='fa fa-spinner fa-spin';
    this.ProgressSpinner=true;
    if(this.data.IsBlocked==true){
      var val=false
    }else{
      var val=true

    }
    let req={
      "BlockedReason":this.Reason,
      "DistributorGUID":this.data.GUID,
      "IsBlocked":val
      }
    this.authService.CrmPostJsonService(this.authService.distributorAPi, 'PostDistributorBlocking', req ).subscribe(res => {
      this.ProgressSpinner=false;
      this.icon='fa fa-check'
      let result:any=res;
      if(result!=null){
        if(result.Response._code=='111'){
          this.dialogRef.close('Success');
          this.toastr.success(result.Response._Description);

        }
      }
    })
   
  }
}
