import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit {

  constructor(public router: Router, public dialogRef: MatDialogRef<CommonPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }
  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  changeSel(e) {
    this.dialogRef.close({ Selected: e });
  }
  okClicked() {
    //this.router.navigate(['/dashboard']);
    /*setTimeout(() => {
      this.router.navigate(['/Master/RegistrationFeedBack']);
   }, 2000);    */
   
    this.dialogRef.close();
  }
}
