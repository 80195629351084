import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';

import { XpertsPageRoutingModule } from './xperts-page-routing.module';
import { XpertsPageComponent } from './xperts-page.component';
import { MatButtonModule, MatCheckboxModule,  MatMenuModule,  MatToolbarModule,  MatIconModule,  MatCardModule, MatSidenavModule,  MatFormFieldModule,  MatInputModule,  MatTooltipModule, MatRadioModule, MatOptionModule, MatAutocompleteModule, MatButtonToggleModule, MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatLineModule, MatListModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatPseudoCheckboxModule, MatRippleModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, NativeDateModule } from '@angular/material';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shared/class'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ClickOutsideModule } from 'ng4-click-outside';
import { PopupUploadComponent } from '../shared/component/popup-upload/popup-upload.component';
import { DeletePopupComponent} from '../shared/delete-popup/delete-popup.component';
import { ModalModule } from "ngx-bootstrap";
//DeletePopupComponent
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    XpertsPageRoutingModule,
    MatButtonModule, MatCheckboxModule,  MatMenuModule,  MatToolbarModule,  MatIconModule,  MatCardModule, MatSidenavModule,  MatFormFieldModule,  MatInputModule,  MatTooltipModule, MatRadioModule, MatOptionModule, MatAutocompleteModule, MatButtonToggleModule, MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatLineModule, MatListModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatPseudoCheckboxModule, MatRippleModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, NativeDateModule,
    LoadingModule,
    NgSelectModule,
    CollapseModule.forRoot(),
    ClickOutsideModule,
    ModalModule.forRoot(),
  ],
  declarations: [ 
    XpertsPageComponent,
    PopupUploadComponent,DeletePopupComponent
  ],
  entryComponents:[PopupUploadComponent,DeletePopupComponent],
  exports: [
    XpertsPageComponent,    
    MatButtonModule, MatCheckboxModule,  MatMenuModule,  MatToolbarModule,  MatIconModule,  MatCardModule, MatSidenavModule,  MatFormFieldModule,  MatInputModule,  MatTooltipModule, MatRadioModule, MatOptionModule, MatAutocompleteModule, MatButtonToggleModule, MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatLineModule, MatListModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatPseudoCheckboxModule, MatRippleModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, NativeDateModule  
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class XpertsPageModule { }
