import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-circular-alert',
  templateUrl: './circular-alert.component.html',
  styleUrls: ['./circular-alert.component.scss']
})
export class CircularAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CircularAlertComponent>,public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      //console.log(data.circular)
     }

  ngOnInit() { }

  onClose(){
    this.dialogRef.close();
  }
}
