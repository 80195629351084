import { Directive,Input } from '@angular/core';
import {  NgForm, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',

  providers: [{ provide: NG_VALIDATORS, useExisting: CustomminDirective, multi: true }]
})

export class CustomminDirective {
  
  @Input()
  customMin: number;

  validate(c: FormControl): { [key: string]: any } {
    console.log(c);
    if(c.value!=''){
      let v = c.value;
      console.log(v,'test',this.customMin, v < this.customMin);
      
      return (v < this.customMin)==false? null:{ "customMin": true };
    }   
  }
  constructor() { }

}
