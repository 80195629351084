// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
export const environment = {
  production: false,
  name: "Dev Mode",
  configFile: "./assets/config/config.json",


  // serviceURL : "http://magnessaapi.betaapps.in",
  // liveURL
  // serviceURL: "http://api2.magnessa.com",
  // serviceURL: "https://backoffice.myfutureverse.io/app",

  // localURLs
  // serviceURL: "http://magapi.betaapps.in",
  serviceURL: "http://fvshopapi.betaapps.in",

  sessionTimeOut: "5",
  menuType: 2,
  uploadFileSize: 15,
  maintenance: false,
  StaticTitle: "Keys CRM | Key to Customer Relation.",
  poweredBy: "ExpertsIn",
  poweredByLink: "http://www.expertsin.com",
  copyRights: " &copy; 2024 Futureverse. All Rights Reserved",
  // copyRights: "Key CRM &copy; 2018-19.",
  companyLogo: "assets/img/companyLogo.png",
  loginlogo: "assets/img/loginLogo.png",
  clientLogo: "assets/img/clientLogo.png",
  DashboardLogo: "assets/img/logo.png",
  // DashboardLogo: "assets/img/DashboardLogo.png",
  clientLogoText: "Magnesa Home Appliances",
  version: "3.5.2",
  googleLoginImage: false,
  MCPPR_MCP: "MCP",
  MCPPR_Branch: "Branch",
  MCPPR_SUPERMCP: "SUPERMCP"
};
