import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../shared/services/index';
import { routerTransition } from '../router.animations';
import { NgForm } from '@angular/forms';
//import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-root',
  templateUrl: 'login.component1.html',
  styleUrls: ['login.component.scss'],
  moduleId: module.id,
  animations: [routerTransition()]
})
export class LoginComponent implements OnInit {
  @ViewChild('forgotPassword') public forgotPassword: ModalDirective;
  CancelRequestData: any = [];
  model: any = { 'username': '', 'password': '', 'remember': false };
  loading = false;
  error = ''; errorMsg = ''; successMsg = '';
  titile = 'Log in';
  footerDetails: any = { poweredBy: "", poweredByLink: "", copyRights: "", companyLogo: "", loginlogo: "", clientLogo: "", clientLogoText: "", };
  version: any = "";
  googleLoginImage: boolean = false;
  alertMessage: any;
  constructor(private TranslateService: TranslateService, private route: ActivatedRoute, private router: Router, public authenticationService: AuthenticationService, public toastr: ToastrService) {
    //maintance page Redirect
    this.TranslateService.get('Admin.login').subscribe(msg => {
      this.alertMessage = msg.message;
    });
    this.route.params.subscribe(params => {
      if (this.authenticationService.env.maintenance) {
        if (params.type != 'devUser')
          this.router.navigate(['/Maintenance']);
      }
    });
    this.footerDetails.poweredBy = this.authenticationService.env.poweredBy;
    this.footerDetails.poweredByLink = this.authenticationService.env.poweredByLink;
    this.footerDetails.copyRights = this.authenticationService.env.copyRights;
    this.footerDetails.companyLogo = this.authenticationService.env.companyLogo;
    this.footerDetails.loginlogo = this.authenticationService.env.loginlogo;
    this.footerDetails.clientLogo = this.authenticationService.env.clientLogo;
    this.footerDetails.clientLogoText = this.authenticationService.env.clientLogoText;
    this.googleLoginImage = this.authenticationService.env.googleLoginImage;

    //, private userIdle: UserIdleService
    let _cookuser = this.getCookie("_myKeysCred");
    if (_cookuser != '')
      this.model = JSON.parse(_cookuser);
  }

  ngOnInit() {
    this.version = this.authenticationService.env.version;
    this.authenticationService.clearLocalStorage(); // reset login status    
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  Anouncement() {
    /* this.authenticationService.CrmGetJsonService(this.authenticationService.adminApi, 'GetUserWorkArea', { 'UserGUID': result.data.UserGUID }).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          localStorage.setItem('CRMWorkArea', JSON.stringify(result.Dropdownlist));
          this.router.navigate(['/dashboard']);
        } else {
          this.error = 'Work Area not Found';
          this.loading = false;
        }
      }
    }); */
  }
  SocialLogin() {
    this.authenticationService.doGoogleLogin().then((data) => {
      console.log(data, 'data');

      //hai jayaram, pls use this code for login
      let login = {
        "FirstName": data.additionalUserInfo.profile.given_name,
        "LastName": data.additionalUserInfo.profile.family_name,
        "Email": data.additionalUserInfo.profile.email,//
        "PhotoURL": data.additionalUserInfo.profile.picture,
        "AuthProfileID": data.additionalUserInfo.profile.id,//
        "AuthProvider": data.additionalUserInfo.providerId//
      };
      let login1 = {
        "loginName": data.additionalUserInfo.profile.email,//
        "PhotoURL": data.additionalUserInfo.profile.picture,
        "AuthProfileID": data.additionalUserInfo.profile.id,//
      };

      /*    this.authenticationService.CrmPostJsonService(this.authenticationService.adminApi, 'GetSignUpBySocialMedia', login1).subscribe(result => {
           console.log(result, 'resres');
           if (result !== null) {
             if (result.Response._code == "111") {
               this.router.navigate(['']);
               this.toastr.success(result.Response._Description, 'Success');
             } else {
               this.toastr.error(result.Response._Description, 'Error');
             }
   
           }
         }) */

      this.authenticationService.login(login1, 1).subscribe(result => {
        sessionStorage.setItem('LoginDetials', JSON.stringify(result.data));

        sessionStorage.setItem('appUrl', JSON.stringify(this.authenticationService.apiService));
        // this.getCallCancelRequest();

        if (result.status === true) { // login successful so redirect to return url          
          if (this.model.remember)
            this.setCookie('_myKeysCred', JSON.stringify(this.model), 6);
          else
            this.setCookie('_myKeysCred', "", 1);

          this.authenticationService.CrmGetJsonService(this.authenticationService.adminApi, 'GetUserWorkArea', { 'UserGUID': result.data.UserGUID }).subscribe(result => {

            if (result !== null) {
              if (result.Response._code == "111") {
                sessionStorage.setItem('CRMWorkArea', JSON.stringify(result.Dropdownlist));


                this.router.navigate(['']);
              } else {
                this.error = 'Work Area not Found';
                this.loading = false;
              }
            }
          });
        } else {
          this.error = 'Admin.login.label.incorrectusePas';
          this.loading = false;
        }


      });

    });
  }
  login() {
    this.loading = true;
    this.authenticationService.login(this.model, 2).subscribe(result => {
      //console.log(result, 'login Detials');
      sessionStorage.setItem('LoginDetials', JSON.stringify(result.data));

      sessionStorage.setItem('appUrl', JSON.stringify(this.authenticationService.apiService));
      // this.getCallCancelRequest();

      if (result.status === true) { // login successful so redirect to return url          
        if (this.model.remember)
          this.setCookie('_myKeysCred', JSON.stringify(this.model), 6);
        else
          this.setCookie('_myKeysCred', "", 1);

        this.authenticationService.CrmGetJsonService(this.authenticationService.adminApi, 'GetUserWorkArea', { 'UserGUID': result.data.UserGUID }).subscribe(result => {

          if (result !== null) {
            if (result.Response._code == "111") {
              sessionStorage.setItem('CRMWorkArea', JSON.stringify(result.Dropdownlist));


              this.router.navigate(['']);
            } else {
              this.error = 'Work Area not Found';
              this.loading = false;
            }
          }
        });
      } else {
        this.error = 'Admin.login.label.incorrectusePas';
        this.loading = false;
      }
    });
  }

  sendPassword(form: NgForm) {
    this.loading = true;
    this.authenticationService.forgotPassword(this.model.email).subscribe(result => {
      if (result === true) {
        this.model.email = '';
        form.resetForm();
        this.loading = false;
        // this.successMsg = 'Admin.login.label.emailSend';
        this.forgotPassword.hide();
        this.toastr.success(this.alertMessage.emailSend);
      } else {
        // this.errorMsg = ;
        this.toastr.error(this.alertMessage.msg003)

        this.loading = false;
      }
    });
  }

  getCallCancelRequest() {
    this.authenticationService.CrmGetJsonService(this.authenticationService.callsApi, 'GetCallCancelRequestList', {}).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.CancelRequestData = result.CancelRequest;
          //console.log(this.CancelRequestData);
          localStorage.setItem('CancelRequestData', JSON.stringify(this.CancelRequestData))
        } else {
          //this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }
}
