import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/index';


// Layouts
import { LoginComponent } from './login/login.component';
import { MaintanceComponent } from './maintance/maintance.component'
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { CallCancelRequestComponent } from './layouts/call-cancel-request/call-cancel-request.component';
import { SettingComponent } from './setting/setting.component';
export const routes: Routes = [
  { path: 'login/:type', component: LoginComponent },
  { path: 'LockScreen', component: LockScreenComponent },
  { path: 'Maintenance', component: MaintanceComponent },
  { path: 'Setting', component: SettingComponent },

  {
    path: '', component: FullLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'CallCancelRequest', component: CallCancelRequestComponent },


      { path: '', loadChildren: './blank/blank.module#BlankModule' },
      { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: 'showcircular', loadChildren: './showcircular/showcircular.module#ShowcircularModule' },
      { path: 'xperts', loadChildren: './xperts-page/xperts-page.module#XpertsPageModule' },
      { path: 'changepassword', loadChildren: './change-password/change-password.module#ChangePasswordModule' },
      { path: 'Master', loadChildren: './master/master.module#MasterModule' },
      { path: 'Franchise', loadChildren: './franchise/franchise.module#FranchiseModule' },
      { path: 'Sales', loadChildren: './sales/sales.module#SalesModule' },
      // { path: 'Calls', loadChildren: './calls/calls.module#CallsModule' },
      { path: 'Accounts', loadChildren: './accounts/accounts.module#AccountsModule' },
      { path: 'Report', loadChildren: './report/report.module#ReportModule' },

      //{ path: 'Report/:id', loadChildren: './report/report.module#ReportModule' },
      { path: 'Inventory', loadChildren: './inventory/inventory.module#InventoryModule' },
      /**  elitehrms */
      { path: 'requestScreen', loadChildren: './request-screen/request-screen.module#RequestScreenModule' },
      { path: 'Employee', loadChildren: './hrdata/hrdata.module#HrdataModule' },
      { path: 'Common', loadChildren: './commondata/commondata.module#CommondataModule' },
      { path: 'Admin', loadChildren: './admin/admin.module#AdminModule' },
      { path: 'Marketing', loadChildren: './marketing/marketing.module#MarketingModule' },
      { path: 'CMS', loadChildren: './cms/cms.module#CmsModule' },

      /** developement */
      { path: 'email', loadChildren: './email/email.module#EmailModule' },
      { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsModule' },
      { path: 'components', loadChildren: './components/components.module#ComponentsModule' },
      { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule' },
      { path: 'charts', loadChildren: './chartjs/chartjs.module#ChartJSModule' },
    ]
  },
  { path: '**', redirectTo: 'page-404' }, /* otherwise redirect to 404 */
  {
    path: 'Maintance1', component: SimpleLayoutComponent, data: { title: 'Pages' },
    children: [{ path: '', loadChildren: './pages/pages.module#PagesModule', }]
  }

  // ,
  // {
  //   path: 'XpertsPage', component: SimpleLayoutComponent, data: { title: 'Search Page' }, canActivate: [AuthGuard],
  //   children: [
  //     { path: '', loadChildren: './hrdata/hrdata.module#HrdataModule' },
  //     { path: 'hrdata', loadChildren: './hrdata/hrdata.module#HrdataModule', }
  //   ]
  // }
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login', component : LoginComponent, data:{ title : 'Login Page' } },
  // { path: 'home', component: FullLayoutComponent, data: { title: 'Home' }, canActivate : [AuthGuard], pathMatch: 'full'}
  //{ path: '', component: FullLayoutComponent, canActivate : [AuthGuard]},
  //{ path: 'login', component: LoginComponent },
  //{ path: 'login', loadChildren: './login/login.module#LoginModule' },
  // { path: '', component: FullLayoutComponent, canActivate : [AuthGuard],
  //   children: [
  //     { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  //     { path: 'components', loadChildren: './components/components.module#ComponentsModule' },
  //     { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
  //     { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule' },
  //     { path: 'charts', loadChildren: './chartjs/chartjs.module#ChartJSModule' },
  //     { path: 'ess', loadChildren: './ess/ess.module#EssModule' }
  //   ]
  // },
  // { path: 'pages', component: SimpleLayoutComponent, data: { title: 'Pages' },
  //   children: [
  //     { path: '', loadChildren: './pages/pages.module#PagesModule',}
  //   ]
  // }
];
//export const Routing = RouterModule.forRoot(routes);
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }