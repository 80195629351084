import { Directive, HostListener } from '@angular/core';

/**
* Allows the sidebar to be toggled via click.
*/

//manually created by venkat
@Directive({
  selector: '[appThemeController]',
})
export class ThemeDirective {
  constructor(){}
  @HostListener('click', ['$event'])
  toggleOpen($event:any){
     $event.preventDefault();
     document.querySelector('.switcher.switch').classList.toggle('active');

  }
}

@Directive({
  selector: '[appFullPage]',
})
export class FullPageDirective {
  constructor(){}
  @HostListener('click', ['$event'])
  toggleOpen($event:any){
     $event.preventDefault();
      var requestFullscreen = function (ele,eleme) {
        // if (ele.requestFullscreen) {
        //   ele.requestFullscreen();
        // } else if (ele.webkitRequestFullscreen) {
        //   ele.webkitRequestFullscreen();
        // } else if (ele.mozRequestFullScreen) {
        //   ele.mozRequestFullScreen();
        // } else if (ele.msRequestFullscreen) {
        //   ele.msRequestFullscreen();
        // } else {
        // }
        if(ele.fullscreenElement||ele.webkitFullscreenElement||ele.mozFullScreenElement||ele.msFullscreenElement) { //in fullscreen, so exit it
		
          if(ele.exitFullscreen) {
            ele.exitFullscreen();
          } else if(ele.msExitFullscreen) {
            ele.msExitFullscreen();
          } else if(ele.mozCancelFullScreen) {
            ele.mozCancelFullScreen();
          } else if(ele.webkitExitFullscreen) {
            ele.webkitExitFullscreen();
          }
        } else { //not fullscreen, so enter i
          if(eleme.requestFullscreen) {
            eleme.requestFullscreen();
          } else if(eleme.webkitRequestFullscreen) {
            eleme.webkitRequestFullscreen();
          } else if(eleme.mozRequestFullScreen) {
            eleme.mozRequestFullScreen();
          } else if(eleme.msRequestFullscreen) {
            eleme.msRequestFullscreen();
          }
        }
      };
      let el=document;
      let elementss=document.documentElement;
      requestFullscreen(el,elementss);

  }
}
//end

@Directive({
  selector: '[appSidebarToggler]'
})
export class SidebarToggleDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-hidden');
  }
}

@Directive({ /* Manually created by harish */
  selector: '[appSidebarTogglerIcon]'
})
export class SidebarToggleIconDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-minimized');
    document.querySelector('body').classList.toggle('brand-minimized');
    
  }
}

@Directive({
  selector: '[appSidebarMinimizer]'
})
export class SidebarMinimizeDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-minimized');
  }
}

@Directive({
  selector: '[appMobileSidebarToggler]'
})
export class MobileSidebarToggleDirective {
  constructor() { }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-mobile-show');
  }
}

/**
* Allows the off-canvas sidebar to be closed via click.
*/
@Directive({
  selector: '[appSidebarClose]'
})
export class SidebarOffCanvasCloseDirective {
  constructor() { }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  // Toggle element class
  private toggleClass(elem: any, elementClassName: string) {
    let newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ' ) + ' ';
    if (this.hasClass(elem, elementClassName)) {
      while (newClass.indexOf(' ' + elementClassName + ' ') >= 0 ) {
        newClass = newClass.replace( ' ' + elementClassName + ' ' , ' ' );
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
      elem.className += ' ' + elementClassName;
    }
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.hasClass(document.querySelector('body'), 'sidebar-off-canvas')) {
      this.toggleClass(document.querySelector('body'), 'sidebar-opened');
    }
  }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [
    ThemeDirective,
    FullPageDirective,
    SidebarToggleDirective,
    SidebarToggleIconDirective, /*created by harish*/
    SidebarMinimizeDirective,
    SidebarOffCanvasCloseDirective,
    MobileSidebarToggleDirective
];
