import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../shared/services/index';
@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})
export class LockScreenComponent implements OnInit {
  username= localStorage.getItem('lockscreen');


  model: any = { 'username': this.username, 'password': ''};
  loading = false;
  error = ''; 
  errorMsg = ''; 
  successMsg = '';
  titile = 'Log in';
  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }
  ngOnInit() {
    // this.onLoggedout();
   
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  login(){
    this.authenticationService.login(this.model.username, this.model.password)
      .subscribe(result => {
        if (result.status === true) { // login successful so redirect to return url          
          if (this.model.remember)
            this.setCookie('_myKeysCred', JSON.stringify(this.model), 6);
          else
            this.setCookie('_myKeysCred', "", 1);

          this.authenticationService.CrmGetJsonService(this.authenticationService.adminApi, 'GetUserWorkArea', { 'UserGUID': result.data.UserGUID }).subscribe(result => {
            if (result !== null) {
              if (result.Response._code == "111") {
                localStorage.removeItem('lockscreen');
                sessionStorage.setItem('CRMWorkArea', JSON.stringify(result.Dropdownlist));
                this.router.navigate(['/dashboard']);
              } else {
                this.error = 'Work Area not Found';
                this.loading = false;
              }
            }
          });
        } else {
          this.error = 'Admin.login.label.incorrectusePas';
          this.loading = false;
        }
      });
  }

}
