import { Component, OnInit, TemplateRef, HostListener, ViewChild } from '@angular/core';
import { AuthenticationService } from '../shared/services/index';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// for modal 
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

// Pagenation
import * as _ from 'underscore';
import { PagerService } from '../shared/pagenation/index'

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { PopupWorkareaComponent } from '../shared/component/popup-workarea/popup-workarea.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CircularAlertComponent } from '../circular-alert/circular-alert.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const EventSource: any = window['EventSource'];
@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
  @ViewChild('showtrachorder') public showtrachorder: ModalDirective;
  isOpen = true;
  ActiveSecondID: any = 50;
  openExtaMenu: any = false;
  CancelRequestData: any = [];
  supportTicketsNotications: any = [];
  public ismenuType: number = 0;
  public ActiveMenuGroupID: number = 0;
  public isProductionMode: boolean = false;
  public userName = "";
  public userEmail = '';
  public userID = '';
  callIdjay: string;
  isHRrole: boolean = false;
  roleName: string = '';
  //ivr navigate
  ivrmenulistProduct: any;
  ivrmenulistCall: any;
  ivrmenu: any;
  timeOutInterval: any;//logout
  //auto logout
  lastAction = Date.now();
  coutDown;
  public loading = false;
  public showBreadcrumb: boolean;
  w: any;
  result: any;
  public cust_number: boolean;
  userWidget: boolean = true;
  objResult: any = [];
  menuList: any = [];
  routerLink: string;
  // profile image source
  imgData: any = {};
  imgFolderPath: string = "64x64/";
  imgFolderPathMenu: string = "128x128/";
  // for modal
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  AnnNotification: boolean = true;
  private allItems = []; // array of all items to be paged
  pager: any = {};// pager object
  pagedItems: any[];// paged items
  // form = new FormGroup({
  //   background: new FormControl('pink'),
  // });
  notificationInfo: any = [];
  notiCount: number = 0;
  notiImagePath: string = '';
  menuGroupList: any = [];
  menuGroupOptions: any = [];
  curArea: string = "";
  WorkAreaList: any = [];
  private themeWrapper = document.querySelector('body');
  knolarity: any = [];
  ivrsCondition: boolean = false;
  ivrEnabled: any;
  agentNumber: any;
  isModalShown: boolean = false;
  circularValue: any = '';
  recentCircular: any = [];
  circularshow: boolean = false;
  intrackorder: SafeResourceUrl;
  outSearch: any;

  footerDetails: any = { poweredBy: "", poweredByLink: "", copyRights: "", companyLogo: "", loginlogo: "", clientLogo: "", DashboardLogo: "" };
  count: any;
  menuName: any;
  notificationShow: boolean = false;
  constructor(public sanitizer: DomSanitizer, public overlayContainer: OverlayContainer, public dialog: MatDialog, private router: Router, private authenticationService: AuthenticationService, private translate: TranslateService,
    private modalService: BsModalService, private pagerService: PagerService, private toastr: ToastrService) {
    this.getdata();
    this.isProductionMode = this.authenticationService.env.production;
    this.footerDetails.poweredBy = this.authenticationService.env.poweredBy;
    this.footerDetails.poweredByLink = this.authenticationService.env.poweredByLink;
    this.footerDetails.copyRights = this.authenticationService.env.copyRights;
    this.footerDetails.DashboardLogo = this.authenticationService.env.DashboardLogo

    this.themeWrapper.style.setProperty('--navColor', '#d7dde4');
    /* this.footerDetails.companyLogo = this.authenticationService.env.companyLogo;
    this.footerDetails.loginlogo = this.authenticationService.env.loginlogo;
    this.footerDetails.clientLogo = this.authenticationService.env.clientLogo; */

    this.ismenuType = this.authenticationService.env.menuType;
    let list = sessionStorage.getItem('CRMWorkArea');
    this.WorkAreaList = (list != undefined) ? JSON.parse(list) : [];
    /* this.authenticationService.loadWorkAreaList().subscribe(x=>{
      this.WorkAreaList = x.data;
      console.log(x);
    });  */


    this.showBreadcrumb = true;
    var objUser;
    objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.menuName = JSON.parse(sessionStorage.getItem('Rl' + objUser.userRoleGUID));
    //this.menuName = JSON.parse(sessionStorage.getItem('selectedMenu'));
    // 'Rl5a9d7652-ca7f-418f-97a6-50498cd34598'
    console.log("this.menuName", this.menuName);

    if (this.menuName != null) {      
      this.menuName.forEach(element => {        
        if (element.MenuGroupName == "Tickets") {         
          this.notificationShow = true;         
        }
        // else{
        //   this.notificationShow = false;
        // }
      });
    }


    this.userName = objUser.userName;
    this.userEmail = objUser.email;
    this.curArea = objUser.WAIDGUID;
    /* this.WorkAreaList= (list!=undefined)? JSON.parse(list):[];
    let id =this.WorkAreaList.findIndex(x=>x.ID== );
    console.log(id);
    console.log(this.WorkAreaList[id]); */
    //this.curArea = this.WorkAreaList[id];

    //hide Below Two API by Bala
    // let req = { WAGUID: objUser.WAIDGUID };
    // this.authenticationService.CrmGetJsonService(this.authenticationService.masterApi, 'GetNewCircular', req).subscribe(result => {
    //   if (result !== null) {
    //     if (result.Response._code == "111") {
    //       this.circularValue = result.NewCircularCount;
    //     }
    //   }
    // })

    // let getcircular = { DisplayType: 0, WAGUID: objUser.WAIDGUID }
    // this.authenticationService.CrmGetJsonService(this.authenticationService.masterApi, 'GetCircularListByDisplay', getcircular).subscribe(result => {
    //   if (result !== null) {
    //     if (result.Response._code == "111") {
    //       this.circularshow = objUser.showCircular;
    //       this.recentCircular = result.Circular;
    //     }
    //   }
    // });

    if (objUser.roleName.toLowerCase() != 'employee') {
      this.roleName = objUser.roleName;
      this.isHRrole = true;
    }
    else {
      this.roleName = objUser.roleName;
      this.isHRrole = false;
    }

    var myDate = new Date();
    var hrs = myDate.getHours();
    var greet;

    if (hrs < 12)
      greet = 'Good Morning' + '!';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon' + '!';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening' + '!';

    this.toastr.success('Hey ' + this.userName + ', ' + greet + '', 'Welcome');
    this.onSetTheme('themes1');
    //web worker
    // if (typeof (Worker) !== "undefined"){ 
    //     this.w = new Worker("/assets/scripts/webworker/webWorker.js");
    //     this.w.postMessage({ 'token':objUser.token,'mobileNumber':this.knolarity.caller.replace('+91',''), 'servicUrl': this.authenticationService.accountApi });
    //     this.w.onmessage = function (event) {
    //       let result = JSON.parse(event.data);
    //       document.getElementById('nameAds').innerHTML = result.Name==null?'':result.Name;
    //     }
    // }
    this.agentNumber = objUser.ivrAgentNumber;
    this.ivrEnabled = objUser.ivrEnabled.toLowerCase() == "true" ? true : false;
    if ((objUser.IvrEnabled == "1") && (objUser.ivrAgentEnabled.toLowerCase() == "true") && (this.agentNumber != "") && (objUser.ivrAgentStatus == "1")) {

      var evtSource = new EventSource(objUser.ivrAPIURL);

      evtSource.onmessage = (e) => {
        let informati = JSON.parse(e.data);
        if (informati["agent_number"] == '+91' + this.agentNumber) {
          if (informati['event_type'] == "ORIGINATE") {
            this.onHidden();
            this.loading = true;
            this.knolarity = informati;
            this.authenticationService.getMobilename(this.knolarity.caller.replace('+91', '')).subscribe(result => {
              this.loading = false;
              this.knolarity['customerName'] = result.Name == null ? '' : result.Name;
              this.ivrsCondition = true;

            });
          } else if ((informati['event_type'] == "ANSWER") || (informati['event_type'] == "BRIDGE")) {

          } else {
            this.ivrsCondition = false;
          }
        }
      }
      evtSource.onerror = (e) => {
        evtSource.close();
      }
      //  evtSource.onopen = (e) => {
      //     console.log('connection open');
      //      console.log(e);
      //  }
    }


    setTimeout(() => {    //<<<---    using ()=> syntax
      let CancelRequest = JSON.parse(localStorage.getItem('CancelRequestData'));
      if (CancelRequest != null && CancelRequest != undefined && CancelRequest.length > 0) {
        //console.log(CancelRequest, 'CancelRequest');
        this.CancelRequestData = JSON.parse(localStorage.getItem('CancelRequestData'));
        //console.log(this.CancelRequestData, 'this.CancelRequestData');
      } else {
        //console.log(CancelRequest, 'CancelEmpty');
        this.CancelRequestData = [];
      }
    }, 100);



  }
  notificationLength() {
    let req = {
      "PageSize": 10,
      "PageIndex": 1,
      "PageOrderby": "",
      "PageDirection": "asc"
    }
    this.authenticationService.CrmPostJsonService(this.authenticationService.supportAPi, 'GetTicketNotificationforBO', req).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.supportTicketsNotications = result.SupportTicketsNotications;
        }
      }
    })
  }
  navigateToList(e) {
    if (e > 0) {
      this.router.navigate(['/Master/nlist']);

    } else {
      console.log('4');

    }

  }


  openExtra(type) {
    if (type == 1) {
      this.openExtaMenu = true;
    } else {
      this.openExtaMenu = false;
    }


  }

  gotoCallRequest() {
    this.router.navigate(['/cancelCallReq']);
  }
  onHidden(): void {
    this.isModalShown = false;
  }

  changeAgent(): void {
    this.isModalShown = true;
  }
  getdata() {
    this.authenticationService.CrmGetJsonService(this.authenticationService.distributorAPi, 'GetDashboard', '').subscribe(res => {
      if(res.Dashboard.length > 0){
        this.count = res.Dashboard[0].TicketsNotUpdate
      }
    })
  }
  getcircular(e) {
    let req = { CircularGUID: e };
    this.authenticationService.CrmGetJsonService(this.authenticationService.masterApi, 'GetCircular', req).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          let dialogRef = this.dialog.open(CircularAlertComponent, {
            width: '500px',
            data: { 'circular': result.Circular }
          });
        }
      }
    })
  }


  updateAgent(e: any) {
    if (e.length >= 10 && e.length <= 15) {
      var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
      objUser.ivrAgentNumber = e;
      this.agentNumber = e;
      this.toastr.success('Updated Successfully', 'Agent Number');
      sessionStorage.setItem('currentUser', JSON.stringify(objUser));
    } else
      this.toastr.error('Not update', 'Agent Number');
  }

  onSetTheme(theme) {
    //this.overlayContainer.getContainerElement().classList.add(theme);    
  }

  printPreview() {
    var prtContent = document.getElementById("printPreview");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    //WinPrint.focus();
    //WinPrint.print();
    //WinPrint.close();
    //window.localStorage.setItem('CompanyName','cgp');
    //window.open('/doc/call-details.html','_blank')
  }
  public status: { isopen: boolean } = { isopen: false };

  public toggled(open: boolean): void {
    //console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }
  ChangeWorkArea(e) {
    // console.log(e);
  }

  ngOnInit() {
    this.notificationLength();

    this.loading = true;
    var objUser;
    // this.watchCall();

    objUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if (objUser.imgPath != undefined && objUser.imgPath != null && objUser.imgName != null) {
      this.notiImagePath = objUser.imgPath + this.imgFolderPath;
      this.imgData.image = objUser.imgPath + this.imgFolderPathMenu + objUser.imgName;
      this.imgData.imageMenu = objUser.imgPath + this.imgFolderPathMenu + objUser.imgName;
    }
    else {
      this.imgData.image = "assets/img/avatars/User.jpg";
      this.imgData.imageMenu = "assets/img/avatars/User.jpg";
      this.notiImagePath = "assets/img/avatars/User.jpg";
    }


    if (sessionStorage.getItem('Rl' + objUser.userRoleGUID) != null) {
      let MenuGroupList = sessionStorage.getItem('Rl' + objUser.userRoleGUID)
      //console.log(sessionStorage.getItem('Rl'+ objUser.userRoleGUID),'1234');
      //console.log('hi-1');
      this.menuGroupList = JSON.parse(MenuGroupList);
      this.ActiveMenuGroupID = this.menuGroupList[0].MenuGroupID;
    }
    else {
      //console.log('hi-2');
      this.authenticationService.getMenuList().subscribe(result => {
        if (result !== null) {
          this.objResult = result;
          //console.log(result, 'result');

          if (this.objResult.MenuGroupList !== null) {

            if (result.RoleName.toLowerCase() == 'employee') {
              this.menuList = this.objResult.MenuGroupList[0].MenuList;
              //  console.log(this.menuList, '1');
            }
            else {
              if (this.isHRrole) {
                this.menuGroupList = result.MenuGroupList;
                //console.log(this.menuGroupList, '  this.menuGroupList');

                this.menuGroupOptions = result.Options;
                this.ActiveMenuGroupID = this.menuGroupList[0].MenuGroupID;

                this.ActiveSecondID = 50;
                //console.log(this.ActiveMenuGroupID, 'this.ActiveMenuGroupID');

                //this.menuList = this.objResult.MenuGroupList[0].MenuList;
              }
            }
            sessionStorage.setItem('Rl' + objUser.userRoleGUID, JSON.stringify(this.menuGroupList));
            sessionStorage.setItem('menuGroupOptions', JSON.stringify(this.menuGroupOptions));
            this.loading = false;
          }

        }
      });

    }

    this.loading = true;
    this.GetNotification();
    // this.authenticationService.GetDashBoardList().subscribe(result => {
    //   if (result === true) {        
    //     this.loading = false;
    //   }
    // });
    this.loading = false;
    this.check();
    this.timeOutInterval = setInterval(() => {
      this.check();
    }, 1000);
    // this.initInterval();
  }
  GetNotification() {
    this.authenticationService.GetNotification().subscribe(result => {
      if (result !== null) {
        this.notificationInfo = result.ApproveNotificationList;
        this.notiCount = this.notificationInfo.length;
      }
    });
  }

  onCircular() {
    if (this.circularValue != '' && this.circularValue != 0)
      this.router.navigate(["/showcircular"]);
  }

  showUserWidget() {
    // if (this.userWidget)
    //   this.userWidget = false;
    // else if (!this.userWidget)
    //   this.userWidget = true;
    this.userWidget = !this.userWidget;
    //console.log(this.userWidget);
  }
  goto(item: any = {}, moduleName: string) {
    //console.log(item, moduleName);
    var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (objUser != null) {
      if (item !== null) {
        item["Module"] = moduleName;
        sessionStorage.setItem('selectedMenu', JSON.stringify(item));
        //this.router.navigate(['/ess/' + item.MenuName]);        
        // this.router.navigate(['/ess/' + item.MenuName]);
        // this.router.isActive('/ess/' + item.MenuName, true);
      }
    }
    else {
      this.onLoggedout();
    }
  }

  myProfile() {
    var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (objUser != null) {
      var item = JSON.parse(sessionStorage.getItem('profileMenu'));
      if (item !== null) {
        sessionStorage.setItem('selectedMenu', JSON.stringify(item));
      }
    }
    else {
      this.onLoggedout();
    }
  }

  getLoginDetails(loginHistory: TemplateRef<any>) {
    this.loading = true;
    this.modalRef = this.modalService.show(loginHistory
      , Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
    this.authenticationService.GetLoginHistory().subscribe(result => {
      if (result != null) {
        this.allItems = result.LoginHistoryDetail;
        this.setPage(1);
      }
      this.loading = false;
    });
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  // onLoggedout() {
  //   this.reset(); //Session Time Out
  //   sessionStorage.removeItem('lockscreen');
  //   clearInterval(this.timeOutInterval)
  //   this.closeWorker()
  //   this.authenticationService.onLoggedout();
  // }
  // onTimeout() {
  //   this.reset(); //Session Time Out
  //   clearInterval(this.timeOutInterval)
  //   this.closeWorker()
  //   this.authenticationService.onTimeout();
  // }
  onLoggedout() {
    // this.closeWorker()
    clearInterval(this.timeOutInterval);
    this.authenticationService.onLoggedout();
  }
  onTimeout() {
    // this.closeWorker()
    clearInterval(this.timeOutInterval);
    this.authenticationService.onTimeout();
  }
  data: any;
  onNewcall(agent_action: any) {
    console.log(agent_action, 'agent_action1');

    this.ivrsCondition = false;
    // this.closeWorker();
    // this.watchCall();
    var objUser;
    objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    sessionStorage.setItem('IVRnumber', this.knolarity.caller.replace('+91', ''));
    // let callid = document.getElementById('callIdAds').innerHTML;
    this.data = {
      "agent_number": this.agentNumber,
      "IVRcall_id": this.knolarity.caller,
      "agent_action": agent_action,
      "agent_guid": objUser.userGUID
    };

    // this.authenticationService.IVRUpdate(this.data)
    //     .subscribe(result => {
    //       console.log(result);
    // }) 
    // var client_number=document.getElementById('phoneNoAds').innerHTML;
    for (let menugrpList of this.menuGroupList) {
      console.log(menugrpList, 'menugrpList');

      if (menugrpList.MenuGroupName.toLowerCase() == 'calls') {
        this.ivrmenu = menugrpList.MenuGroupName;
        for (let selectmenu of menugrpList.MenuList) {
          if (selectmenu.MenuName.toLowerCase() == 'call register') {
            this.ivrmenulistCall = selectmenu;
            switch (agent_action) {
              case 1:
                this.goto(this.ivrmenulistCall, this.ivrmenu);
                // console.log(agent_action, 'agent_action2');
                this.router.navigate(['/Calls/CallRegister/1']);
                if (location.hash == '#/Calls/CallRegister/1') {
                  this.onReload();
                }
                break;
              case 2:
                // window.open('#/Calls/Calls','_self');
                this.goto(this.ivrmenulistCall, this.ivrmenu);
                this.router.navigate(['/Calls/CallRegister/1']);
                if (location.hash == '#/Calls/CallRegister/1') {
                  this.onReload();
                }
                // location.reload();
                break;
            }
          } else if (selectmenu.MenuName.toLowerCase() == 'warrantyregistration') {
            this.ivrmenulistProduct = selectmenu;
            this.goto(this.ivrmenulistProduct, this.ivrmenu);
            this.router.navigate(['/Master/WarrantyRegistration/3']);
            if (location.hash == '#/Master/WarrantyRegistration/3') {
              this.onReload();
            }
          }

        }
      }
    }

    // switch (agent_action) {
    //   case 1:
    //     this.goto(this.ivrmenulistCall, this.ivrmenu);
    //     // console.log(agent_action, 'agent_action2');
    //     this.router.navigate(['/Calls/CallRegister/1']);
    //     if (location.hash == '#/Calls/CallRegister/1') {
    //       this.onReload();
    //     }
    //     break;
    //   case 2:
    //     // window.open('#/Calls/Calls','_self');
    //     this.goto(this.ivrmenulistCall, this.ivrmenu);
    //     this.router.navigate(['/Calls/CallRegister/1']);
    //     if (location.hash == '#/Calls/CallRegister/1') {
    //       this.onReload();
    //     }
    //     // location.reload();
    //     break;
    //   case 3:
    //     this.goto(this.ivrmenulistProduct, this.ivrmenu);
    //     this.router.navigate(['/Master/WarrantyRegistration/3']);
    //     break;
    // }

    // if(agent_action==1){
    //   this.router.navigate(['/Calls/Calls']);
    //   window.open('/Calls/Calls/call_num='+client_number+',agent_action='+agent_action,'_self');
    // }
  }

  onReload() {
    //setTimeout(() => { 
    location.reload();
    //}, 3000);
  }

  openDialog(data): void {
    let dialogRef = this.dialog.open(PopupWorkareaComponent, {
      width: '250px',
      data: { 'List': this.WorkAreaList }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result != undefined) {
        this.curArea = result.Selected;

        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        objUser.WAIDGUID = this.curArea;
        objUser.WorkAreaName = result.name;
        sessionStorage.setItem('currentUser', JSON.stringify(objUser));
        this.router.navigate(['/dashboard']);
      }
    });
  }
  // watchCall() {
  // var objUser;
  // objUser = JSON.parse(sessionStorage.getItem('currentUser'));
  // if ((objUser.AgentNumber != null) && (objUser.AgentNumber != '') && (objUser.ivrEnabled.toLowerCase() == 'true')) {
  //   if (typeof (Worker) !== "undefined") {
  //     if (typeof (this.w) !== "undefined") {
  //       this.w = new Worker("/assets/scripts/webworker/webWorker.js");
  //       this.w.postMessage({ 'agentNumber': objUser.AgentNumber, 'servicUrl': this.authenticationService.getcallagent });
  //       this.w.onmessage = function (event) {
  //         let date = new Date();
  //         this.result = JSON.parse(event.data);
  //         if (this.result.Response._code == '111') {
  //           document.getElementById('callIdAds').innerHTML = this.result.knowlarity[0].call_id;
  //           document.getElementById('agentNoAds').innerHTML = this.result.knowlarity[0].agent_number;
  //           document.getElementById('timeAds').innerHTML = this.result.knowlarity[0].Date;
  //           document.getElementById('phoneNoAds').innerHTML = this.result.knowlarity[0].cust_number;
  //           document.getElementById('nameAds').innerHTML = this.result.knowlarity[0].Cust_Name;
  //           sessionStorage.setItem('IVRnumber', this.result.knowlarity[0].cust_number);
  //           // document.getElementById('result').innerHTML='<div class="row justify-content-center" style="margin: auto;position: fixed;top: 46%;left: 40%;"><div ><div class="card mb-0"><div class="card-body pt-0 pb-1"><div class="d-inline">Customer Name :<span style="border-bottom:1px solid #333e48;margin:0 5px;padding: 0 5px;"> '+ objUser.userName +'</span></div><div class="d-inline ml-1 mr-1"> Customer Number :<span style="border-bottom:1px solid #333e48;margin:0 5px;padding: 0 5px;">'+ this.result.knowlarity[0].cust_number  +' </span></div> <div class="d-inline ml-1 mr-1"> <i class="fa fa-check fa-lg mt-4" title="close" style="cursor:pointer;"></i>  </div><div class="d-inline ml-1 mr-1"> <i id="close" class="fa fa-close fa-lg mt-4" title="close" style="cursor:pointer;" (click)="return closeBaner();"></i>  </div></div></div></div></div>';
  //           let elem: HTMLElement = document.getElementById('cards');
  //           elem.setAttribute("style", "display:block;");
  //         }
  //       };
  //     }
  //   }
  // }

  // }

  // closeWorker() {
  //   if (typeof (this.w) != "undefined") {
  //     this.w.terminate();
  //     this.w = undefined;
  //   }
  //   let elem: HTMLElement = document.getElementById('cards');
  //   elem.setAttribute("style", "display:none;");
  // }
  // stopWorker() {
  //   this.closeWorker()
  //   this.toastr.success('Thank you');
  //   setTimeout(() => { this.watchCall(); }, 10000);
  // }
  // closeBaner() {
  //   this.closeWorker();
  //   setTimeout(() => { this.watchCall(); }, 10000);
  // }

  @HostListener('keypress') onkeyPress() {
    this.reset()
  }
  @HostListener('mouseover') onMouseOver() {
    this.reset()
  }
  @HostListener('click') onClick() {
    this.reset()
  }
  @HostListener('keyup') onKeyUp() {
    this.reset()
  }
  @HostListener('keydown') onkeyDown() {
    this.reset()
  }
  @HostListener('mouseout') onMouseOut() {
    this.reset()
  }

  reset() {
    this.lastAction = Date.now();
    // let elem = document.querySelector('body').classList;
    // if(this.router.url=="#/showcircular"){

    //   if(elem.value.indexOf("circular")==-1)
    //     elem.add("circular");

    // }else{

    //   if(elem.value.indexOf("circular")!=-1)
    //     elem.remove("circular");
    // }
  }
  approve(index, type) {
    if (type == 1) {
      var req = { 'CallGuid': this.CancelRequestData[index].CallGuid, 'RequestType': 'Approved' };
    }
    if (type == 2) {
      var req = { 'CallGuid': this.CancelRequestData[index].CallGuid, 'RequestType': 'Rejected' };
    }
    this.authenticationService.CrmPostJsonService(this.authenticationService.callsApi, 'PostCallRequestUpdate', req).subscribe(result => {

      if (result !== null) {
        if (result.Response._code == "111") {
          this.CancelRequestData.splice(index, 1);
          this.toastr.success(result.Response._Description, "Success");
        } else {
          this.toastr.error(result.Response._Description, "Error");
        }
      }
    });

  }
  check() {
    /*   let defaultsessionTimeOut: any = 20;
      const now = Date.now();    
      if (this.authenticationService.timeOut == "" && this.authenticationService.timeOut == null && this.authenticationService.timeOut == undefined) {
        defaultsessionTimeOut = defaultsessionTimeOut + 1;
      } else {
        defaultsessionTimeOut = parseInt(this.authenticationService.timeOut);
      }
      const timeleft = this.lastAction + parseInt(defaultsessionTimeOut) * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = Math.floor(diff / 1000);
      if (isTimeout < 60) {
        this.coutDown = 'Your session is about to expire in ' + isTimeout + ' seconds! ';
        this.AnnNotification = true;
      }
      else {
        this.coutDown = '';
        this.AnnNotification = false;
      }
      if (this.authenticationService.timeOut > 0) {
        if ((isTimeout == 0) && (diff > -1000) && (location.hash != '#/LockScreen') && (location.hash != '#/login/login')) {
          localStorage.setItem('lockscreen', this.userName);
          this.onTimeout();
          // this.router.navigate(['/LockScreen']);
          // this.authenticationService.lockScreen();
        }
      } */

  }
  onTeminal(event) {
    console.log(event.target.value);
  }

  _restrictAlphabets(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9)
        console.log("backspace");
      else
        event.preventDefault();
    }
  }
  themeColor(color) {
    let elem: HTMLElement = document.getElementById('navbar');
    elem.setAttribute("style", color);
    //  document.querySelector('.app-header.navbar').setAttribute("style", color);
  }

  hidecircular() {
    this.circularshow = false;
    let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    objUser.showCircular = false;
    sessionStorage.setItem('currentUser', JSON.stringify(objUser));
  }

  Trackproduct(val) {
    console.log("val", val);

    // var url = "http://track.transkart.com/?waybill=" + val;
    // var url = "https://www.delhivery.com/track/package/" + val;    
    var url = "https://www.delhivery.com/track/package/" + val;
    // this.intrackorder = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // $("#loadTrackOrder").load(url);
    // $("#loadTrackOrder").html('<object style="width: 100%;height: 515px;" data="' + url + '"/>');
    window.open(url, "", "width=1200,height=700")


    // this.intrackorder= this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // this.showtrachorder.show();
  }
}

