import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {  NgForm } from '@angular/forms';
@Component({
  selector: 'app-approved-popup',
  templateUrl: './approved-popup.component.html',
  styleUrls: ['./approved-popup.component.scss']
})

export class ApprovedPopupComponent implements OnInit {
model:any={};
  constructor(public dialogRef: MatDialogRef<ApprovedPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ProgressSpinner:boolean;
  ngOnInit() {
  }
      Approved:""

  close(){
    this.dialogRef.close();
  }
  
  cancel(){
    this. Approved=""
  }
  
  postForm(form:NgForm){
    this.dialogRef.close(this.Approved);
  
  }
}
