import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { XpertsPageComponent } from './xperts-page.component';

import { PeriodComponent } from '../commondata/period/period.component';
import { AuthGuard } from '../shared/guards';

const routes: Routes = [
  //{ path: '', component: XpertsPageComponent, data: { title: 'Xperts Page' } }
  {
    path: '', data: {title: 'Xperts Page'}, canActivate: [AuthGuard], component: XpertsPageComponent,
    children: [
      { path: 'Common-Periods', component: XpertsPageComponent, data: {title: 'Period'} },      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class XpertsPageRoutingModule { }
