import { Directive,Input } from '@angular/core';
import {  NgForm, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',

  providers: [{ provide: NG_VALIDATORS, useExisting: CustommaxDirective, multi: true }]
})
export class CustommaxDirective {
  @Input()
  customMax: number;

  validate(c: FormControl): { [key: string]: any } {
    if(c.value!=''){
    
      let v = c.value;
      return   (v > this.customMax) ? { "customMax": true } : null;
    }   
   
  }
  constructor() { }

}
