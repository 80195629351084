import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quickSearch'
})
export class QuickSearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const filterValue = (args!=null)?args.toLowerCase():'';
    if(value!=null && value!=undefined)
      return value.filter(x => x.Value.toLowerCase().indexOf(filterValue) !== -1); //it returns typing value ==array value
  }

}
