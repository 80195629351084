import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
  maxlength: Date;
  minlength: Date;
  minl: Date;
  maxl: Date;
  twoDecimal:number=3
    user: any;
    maxPageIndex: number;
    currentIndexFrom: number;
    currentIndexTo: number;
  mcpmaxPageIndex: number;
  mcpcurrentIndexFrom: number;
  mcpcurrentIndexTo: number;
    imgcurrentIndexFrom: number;
    imgcurrentIndexTo: number;

  constructor() { 
    let min= new Date();
    this.minlength = new Date( min.getFullYear() - 100,0, 1);
    this.maxlength= new Date(min.getFullYear() + 100, 11, 31);
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
  }  
  decimal(event,val){
   
    event.target.value = Number(event.target.value).toFixed(val);
  }
 
 
  public _IFSCFormat(event: any) {
    const pattern = /^[A-Za-z0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
  }
  public _onlyAplha(event: any) {
    const pattern = /^[A-Za-z ]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
  }
  public _onlyNumbers(event: any) {
    console.log("_onlyNumbers");
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
}
public  emiratesIDFormat(event:any) {
    const pattern = /^[0-9-A-Z]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
}
  public _onlyNumbersWithDecimal(event: any) {
    const pattern = /^[0-9.]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
}
public _alphaCharacters(event: any) {
    const pattern = /^[A-Za-z0-9 .]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
}
public _alphaCharactersNoDecimal(event: any) {
    const pattern = /^[A-Za-z0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        if (event.keyCode == 8 || event.keyCode == 9)
            console.log("backspace");
        else
            event.preventDefault();
    }
}
public _alphaAndSpecialchar(event: any) {
  const pattern =  /^[a-z_A-Z.0-9- @#%!]/;
  let inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9)
          console.log("backspace");
      else
          event.preventDefault();
  }
}
findPageIndexLength(totalCount, pageSize, pageIndex) {   
    let maxIndex: any = totalCount / pageSize
   
    this.maxPageIndex=Math.ceil(maxIndex);
    console.log(this.maxPageIndex);
    
    this.currentIndexFrom = pageIndex * 10 - 9
    this.currentIndexTo = pageIndex * 10
  }
findMCPPageIndexLength(totalCount, pageSize, pageIndex) {   
    let maxIndex: any = totalCount / pageSize
     this.mcpmaxPageIndex=Math.ceil(maxIndex);
    console.log(this.mcpmaxPageIndex);
    this.mcpcurrentIndexFrom = pageIndex * 10 - 9
    console.log(this.mcpcurrentIndexFrom );
    
    this.mcpcurrentIndexTo = pageIndex * 10
  }
findImagPageIndexLength(totalCount, pageSize, pageIndex) {   
    
    let from=(pageIndex * Number(pageSize))+1;
     
    this.imgcurrentIndexFrom = from- Number(pageSize)
    this.imgcurrentIndexTo = pageIndex * pageSize;
  }
}
