import { Component, OnInit, Output, EventEmitter, Input, SimpleChange } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-attachment-popup',
  templateUrl: './attachment-popup.component.html',
  styleUrls: ['./attachment-popup.component.scss']
})
export class AttachmentPopupComponent implements OnInit {
  @Output() uploaded = new EventEmitter<any>();
  @Input() InputData: any = [];
  showSideBar: boolean = false;
  pageData: any = {};
  PageFileList: any = [];
  model: any = { 'FileData': "", 'Desc': "" };
  FileUploadData: any = { 'base64': "", 'name': "", 'size': "" };
  //FileUploadData:any=[];
  fileSizeLimit: number = 0;
  mb: number = 1048576;
  currentUser: any;
  constructor(private authService: AuthenticationService,
    private toastr: ToastrService) {
    this.pageData = JSON.parse(sessionStorage.getItem('selectedMenu'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.PageFileList = changes['InputData'].currentValue;
  }
  calculateSize(val) {
    if (val == undefined || typeof (val) != 'number')
      return 1048576;
    return val * this.mb;
  }
  ngOnInit() {
    this.fileSizeLimit = this.calculateSize(this.authService.env.uploadFileSize);
    this.authService.env.uploadFileSize;
    document.getElementById("eisUploadRightSideBar").style.width = "20%";
  }
  UploadResponse(e) {
    this.uploaded.emit({ 'show': e, 'data': this.PageFileList });
  }
  postForm(frm: NgForm) {
    let req = {
      "FileName": this.FileUploadData.name,
      "base64String": this.FileUploadData.base64,
      "FilePath": "",
      "PageName": this.pageData.MenuName,
      "PrimaryKeyGUID": UUID.UUID(),
      "PageGUID": this.pageData.PageGUID,
      "FileSizeInBytes": this.FileUploadData.size,
      "FileGUID": UUID.UUID(),
      "IPAddress": "",
      "FileMode": "post",
      "Description": this.model.Desc,
      "WAIDGUID": this.currentUser.WAIDGUID,
      "LoginGUID": this.currentUser.userGUID,
      "UploadFileList": []
    }
    this.authService.CrmPostJsonService(this.authService.adminApi, 'PostFileHandling', req).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.PageFileList.push({ 'FileGUID': req.FileGUID, 'FileName': req.FileName, 'Description': req.Description, 'FilePath': result.FilePath });
          frm.resetForm();
          this.UploadResponse(true);
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }
  base64(file) {
    var myReader: FileReader = new FileReader();
    var _this = this;
    myReader.onloadend = function (loadEvent: any) {
      _this.FileUploadData.base64 = loadEvent.target.result.split(",")[1];
      _this.FileUploadData.name = file.name;
      _this.FileUploadData.size = file.size;

    };
    /*  this.FileUploadData.push(_this.FileUploadData);
     console.log("this.FileUploadData",this.FileUploadData); */
    if (file)
      myReader.readAsDataURL(file);
  }
  fileUpload(e) {
    if (e.target.files[0].size < this.fileSizeLimit)
      this.base64(e.target.files[0]);
    else {
      this.model.FileData = "";
      this.toastr.error('Please upload file size less than' + (this.fileSizeLimit / this.mb).toString() + 'mb')
    }
  }
  openFile(e) {
    let url = this.authService.apiService + '/' + this.PageFileList[e].FilePath + '/' + this.PageFileList[e].FileGUID + '_' + this.PageFileList[e].FileName;
    console.log(url, 'url');
    window.open(url, '_blank');
  }
  clear(frm: NgForm) {
    frm.resetForm();
  }
  deleteFile(e) {
    let req = {
      "FileName": '',
      "base64String": "",
      "FilePath": "",
      "PageName": "",
      "PrimaryKeyGUID": "",
      "PageGUID": "",
      "FileSizeInBytes": "",
      "FileGUID": this.PageFileList[e].FileGUID,
      "IPAddress": "",
      "FileMode": "remove",
      "Description": "",
      "WAIDGUID": "",
      "LoginGUID": "",
      "UploadFileList": []
    }
    this.authService.CrmPostJsonService(this.authService.adminApi, 'PostFileHandling', req).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.PageFileList.splice(e, 1);
          this.UploadResponse(true);
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }

}
