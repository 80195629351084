// import { Component, OnInit, Inject } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { AuthenticationService } from '../../shared/services/authentication.service';
// import { ToastrService } from 'ngx-toastr';
// import { UUID } from 'angular2-uuid';
// import { NgForm } from '@angular/forms';
// @Component({
//   selector: 'app-self-pickup-popup',
//   templateUrl: './self-pickup-popup.component.html',
//   styleUrls: ['./self-pickup-popup.component.scss']
// })
// export class SelfPickupPopupComponent implements OnInit {
//   model: any = {};
//   PRID: any;
//   totalPV: any;
//   GUID: any;
//   updatebtn: boolean = false;
//   ProgressSpinner: boolean = false;
//   constructor(private toastr: ToastrService, private authService: AuthenticationService, public dialogRef: MatDialogRef<SelfPickupPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
//     console.log("data==>", data)
//     this.PRID = data.PRNO;
//     this.totalPV = data.PRTotalPV;
//     this.GUID = data.PRGUID
//     console.log(" this.totalPV===>", this.totalPV)
//     console.log("this.GUID==>", this.GUID)
//   }
//   ngform() {
//     this.model = {
//       "PRGUID": this.GUID,
//       "PRPV": "",
//       "PRSV": ""
//     }
//   }

//   ngOnInit() {

//   }
//   close() {
//     this.dialogRef.close();
//   }
//   cancel() {
//     this.ngform();
//   }
//   prsvAmount() {
//     let total = Number(this.model.PRPV) + Number(this.model.PRSV)
//     console.log("PRSV====>", total)
//     if (total == this.totalPV) {
//       console.log("total====>", total)
//       this.updatebtn = true;
//     }
//     else {
//       this.updatebtn = false;
//     }
//   }
//   postForm(form: NgForm) {
//     console.log(" this.model==>", this.model)
//     console.log("this.GUID,post;", this.GUID)
//     this.model.PRGUID = this.GUID;
//     this.ProgressSpinner = true;
//     this.authService.CrmPostJsonService(this.authService.SalesApi, 'PostPurchasePVSVUpdate', this.model).subscribe(result => {
//       this.ProgressSpinner = false;

//       if (result != null) {
//         if (result.Response._code == '111') {
//           this.toastr.success(result.Response._Description);
//           this.dialogRef.close('success');
//         } else {
//           this.toastr.error(result.Response._Description);
//         }
//       }
//     })

//   }
// }
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-self-pickup-popup',
  templateUrl: './self-pickup-popup.component.html',
  styleUrls: ['./self-pickup-popup.component.scss']
})
export class SelfPickupPopupComponent {
  model: any = {};
  CountryList: any = [];
  StateList: any = [];
  currentUser: any;
  value: any;
  constructor(private toastr: ToastrService, public authService: AuthenticationService, public dialogRef: MatDialogRef<SelfPickupPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || "{}");
    // this.value = data;
    setTimeout(() => {
      if (data.Type == "Edit") {
        console.log('data.Value', data.Value)
        console.log("edit")
        this.model = {
          "guidAddressID": data.Value.guidAddressID,
          "guidTransID": data.Value.guidTransID,
          "FirstName": data.Value.FirstName,
          "LastName": data.Value.LastName,
          "MobileNo": data.Value.MobileNo,
          "Email": data.Value.Email,
          "doorNo": data.Value.doorNo,
          "Street": data.Value.Street,
          "City": data.Value.City,
          "StateID": data.Value.StateID,
          "CountryID": data.Value.CountryID,
          "PostalCode": data.Value.PostalCode,
          "Landmark": data.Value.Landmark,
          "AddressType": data.Value.Landmark,
          "isShippingAddress": data.Value.isShippingAddress,
        }
        console.log('model', this.model)
      }
      if (data.Type == "Create") {
        console.log('data.Value', data.Value)
        console.log('create')
        this.value = data.Value;
        this.model = {
          "guidAddressID": UUID.UUID(),
          "guidTransID": this.value,
          "FirstName": "",
          "LastName": "",
          "MobileNo": "",
          "Email": "",
          "doorNo": "",
          "Street": "",
          "City": "",
          "StateID": null,
          "CountryID": null,
          "PostalCode": "",
          "Landmark": "",
          "AddressType": "1",
          "isShippingAddress": true
        }
      }
    }, 500);

  }

  newForm() {

    this.model = {
      "guidAddressID": UUID.UUID(),
      "guidTransID": this.value,
      "FirstName": "",
      "LastName": "",
      "MobileNo": "",
      "Email": "",
      "doorNo": "",
      "Street": "",
      "City": "",
      "StateID": null,
      "CountryID": null,
      "PostalCode": "",
      "Landmark": "",
      "AddressType": "1",
      "isShippingAddress": true
    }
  }
  ngOnInit() {
    this.newForm();
    this.loadDropdown()
  }
  loadDropdown() {
    this.authService.GetDropDownList('Country').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == 111) {
          this.CountryList = result.Dropdownlist;
        }
      }
    });

  }
  countryBaseState(val) {
    console.log('StateList', val);
    this.authService.GetDropDownList('State', val).subscribe((result: any) => {
      if (result != null) {
        if (result.Response._code == 111) {
          this.StateList = result.Dropdownlist;
        }
      }
    });
  }

  AddressPostFn(form: NgForm) {
    if (form.valid) {
      this.model.isShippingAddress = this.model.isShippingAddress ? 1 : 0;
      this.model.AddressType = 'H',
        this.authService.CrmPostJsonService(this.authService.CustomerApi, 'PostAddress', this.model).subscribe((res: any) => {
          if (res != null) {
            if (res.Response._code == 111) {
              // this.toastr.success(res.Response._Description);
              this.dialogRef.close(this.model);
            }
            else {
              this.toastr.error(res.Response._Description);
            }
          }
        }, (err) => {
        });
    }
  }
  postForm() {

  }
  clear() {
    this.newForm();
  }

  close() {
    this.dialogRef.close('No');
  }
}
