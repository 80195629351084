import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayPair'
})
export class ArrayPairPipe implements PipeTransform {

  transform(value: any, args?: any, key?: string, pair?: string): any {
    if (  (value == undefined || value == '') &&
          (args == undefined || args == '') &&
          (key == undefined || key == '') &&
          (pair == undefined || pair == '') )
      return null;
    let i = args.findIndex(x => x[key] == value);
    return (i > -1) ? args[i][pair]: 'Undefiend';  
}

}
