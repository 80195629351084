import { Component, OnInit, Inject } from '@angular/core';
//import {Component, Inject} from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { XpertsPageComponent } from '../../xperts-page/xperts-page.component';
import { from } from 'rxjs/observable/from';
@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  DeleteItem: any = {};
  objUser: any;
  CreatedBy: any;
  ModifiedBy: any;
  ProgressSpinner:boolean;
  //xperts: XpertsPageComponent;
  constructor(public dialogRef: MatDialogRef<DeletePopupComponent>, public authenticationService: AuthenticationService, public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) {
    //console.log(data, 'data');

  }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close('No');
  }
  onOkClick(): void {
    this.dialogRef.close('Yes');
  }
}
