import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import{CustomminDirective} from './custommin.directive';
import { CustommaxDirective } from './custommax.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],  
  exports:[]  
})
export class DirectiveModule {  }