import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import { HttpEvent } from '@angular/common/http/src/response';
import { AngularFireAuth } from 'angularfire2/auth';
//import * as firebase from 'firebase/app';
import { auth } from 'firebase';
//import { parse } from 'path';

import { environment } from '../../../environments/environment';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
// import { UserIdleService } from 'angular-user-idle';
import { Router, ActivatedRoute } from '@angular/router';
//import { try ,catch } from 'rxjs';

//import { ErrorHandler } from '@angular/core';


@Injectable()
export class AuthenticationService {
    sumitSideChanges: boolean = false //test
    public token: string;
    public apiUrl: string;
    public Anouncement: any = { 'status': false, 'data': {} };
    /* environment data inclution : devMode/prodMode  */
    public env: any = environment;
    public apiMode: string = environment.name;
    public apiConfigPath: any = environment.configFile;
    public apiService: string = environment.serviceURL;
    public timeOut: any = environment.sessionTimeOut;

    ///this.config.serviceURL; /* public apiService: string = "http://devapi.elitehrms.com"; */
    public supportAPi: string = this.apiService + "/api/Support/";
    public distributorAPi: string = this.apiService + "/api/Distributor/";
    public adminApi: string = this.apiService + "/api/admin/";
    public MagcommonApi: string = this.apiService + "/api/Magcommon/";
    public cmsApi: string = this.apiService + "/API/CMS/";
    public cmsPageApi: string = this.apiService + "/api/CMS/";
    public marketingApi: string = this.apiService + "/API/Marketing/";
    public marketingapi: string = this.apiService + "/api/Marketing/";
    public maglopApi: string = this.apiService + "/api/Maglopss/";
    public cartApi: string = this.apiService + "/api/Cart/";
    public commonApi: string = this.apiService + "/api/common/";
    public employeeApi: string = this.apiService + "/api/employee/";
    public payrollApi: string = this.apiService + "/api/payroll/";
    public attendanceApi: string = this.apiService + "/api/Attendance/";
    public assetApi: string = this.apiService + "/api/Asset/";
    public itApi: string = this.apiService + "/api/IT/";
    public appraisalApi: string = this.apiService + "/api/Appraisal/";
    public trainingApi: string = this.apiService + "/api/Training/";
    //CRM

    public magiCraftApi: string = this.apiService + "/api/MagicCraft/";
    public masterApi: string = this.apiService + "/api/Master/";
    public AdminApi: string = this.apiService + "/api/Admin/";
    public accountApi: string = this.apiService + "/api/Accounts/";
    public transApi: string = this.apiService + "/api/Transaction/";
    public bonusAPi: string = this.apiService + "/api/Bonus/";
    public getcallagent: string = this.apiService + "/api/knowlarity/";
    public grievanceApi: string = this.apiService + "/api/Grievance/";
    public InventoryApi: string = this.apiService + "/api/Inventory/";
    public CRMApi: string = this.apiService + "/api/CRM/";
    public franchiseAPi: string = this.apiService + "/api/Franchise/";

    public SalesApi: string = this.apiService + "/api/Sales/";
    public WalletApi: string = this.apiService + "/api/Wallet/";
    public PMSApi: string = this.apiService + "/api/PMS/";
    public callsApi: string = this.apiService + "/api/Calls/";
    public stockApi: string = this.apiService + "/api/Stock/";
    public CustomerApi: string = this.apiService + "/api/Customer/";


    public EiUtils: string = this.apiService + "/api/EiUtils/"; //delete

    //'http://snsapi.betaapps.in/api/EiUtils/PostDelete'

    //End CRM

    /* Public variable used for all pages */
    public screenID: number; /* Page screen ID */
    public loading: boolean; /* Page loading mode */

    public historyAlerts: any = []; /* showing history details */
    public pendingAlerts: any = []; /* showing pending details */
    public approvedAlerts: any = []; /* showing approved details */
    public objResult: any = {};

    public defaultUserImage: string = "assets/img/avatars/User.jpg"; /* default user image path */
    public imgFolderPath: string = "300x300/"; /* default folder path */
    public objUser: any = {}; /* user details object */
    public imgData: any = {}; /* user image variable */

    public model: any = {}; /* two way binding variable */
    public WorkAreaList: any = [];
    public currentUser: any;
    /* --------------------------------------- */

    constructor(public afAuth: AngularFireAuth, private http: Http, private toastr: ToastrService, private router: Router, private httpService: HttpClient) {

        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        // , private userIdle: UserIdleService         
        this.sessionInit();
        toastr.toastrConfig.progressBar = true;
        toastr.toastrConfig.closeButton = true;
        toastr.toastrConfig.preventDuplicates = true;
        toastr.toastrConfig.enableHtml = true;


        /* .options = {
           "closeButton": true,
           "debug": false,
           "newestOnTop": false,
           "progressBar": true,
           "positionClass": "toast-top-right",
           "preventDuplicates": false,
           "onclick": null,
           "showDuration": "300",
           "hideDuration": "1000",
           "timeOut": "5000",
           "extendedTimeOut": "1000",
           "showEasing": "swing",
           "hideEasing": "linear",
           "showMethod": "fadeIn",
           "hideMethod": "fadeOut"
         }
         */

        /* set token if saved in local storage */
        this.objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (sessionStorage.getItem('currentUser') === null) {
            this.token = "";
            this.stopWatching();
            this.clearLocalStorage();
        } else {
            var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.token = currentUser && currentUser.token;
            this.sessionInit();
        }
    }
    validString(str) {
        if (typeof (str) == 'string' && str != '')
            return true;
        return false
    }
    sessionInit() {
        //Start watching for user inactivity.
        //this.userIdle.startWatching();

        // Start watching when user idle is starting.
        // this.userIdle.onTimerStart().subscribe(count => {
        //   
        // });

        // Start watch when time is up.
        // this.userIdle.onTimeout().subscribe(() => {
        //     this.onLoggedout();
        // });
    }
    loadWorkAreaList(): Observable<any> {
        let list = localStorage.getItem('CRMWorkArea');
        let d = { 'data': (list != undefined) ? JSON.parse(list) : [] };
        return new Observable((observer) => { observer.next(d); });
    }

    //google login 
    doGoogleLogin() {
        return new Promise<any>((resolve, reject) => {
            let provider = new auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            this.afAuth.auth.signInWithPopup(provider).then(res => {
                console.log(res, 'res');
                resolve(res);
            }, err => {
                //console.log(err);
                reject(err);
            })
        })
    }


    //#region Services URL
    login(value, type): Observable<any> {

        var data: any;
        if (type == 1) {
            this.apiUrl = this.adminApi + "GetSignUpBySocialMedia";
            data = {
                "loginName": value.loginName,
                "PhotoURL": value.PhotoURL,
                "AuthProfileID": value.AuthProfileID
            }
        } if (type == 2) {
            this.apiUrl = this.adminApi + "getsignin";
            data = {
                "loginName": value.username,
                "loginpassword": value.password
            };
        }

        //return this.http.post('/api/ticate', JSON.stringify({ username: username, password: password }))
        return this.http.post(this.apiUrl, data).map((response: Response) => {
            // login successful if there's a jwt token in the response
            //let token = response.json() && response.json().token;
            if (response.json().Token != null) {
                let token = response.json().Response._status && response.json().Token.Token;
                if (token && response.json().Response._code == "111") {
                    this.token = token; // set token property
                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('currentUser', JSON.stringify({
                        country: response.json().Country,
                        userID: response.json().UserID,
                        userGUID: response.json().UserGUID,
                        userName: (response.json().DisplayName == null || response.json().DisplayName == '') ? response.json().UserName : response.json().DisplayName,
                        email: response.json().UserEmail,
                        token: response.json().Token.Token,
                        userEmail: response.json().UserEmail,
                        userRoleID: response.json().RoleID,
                        ivrEnabled: response.json().IVRENABLED,
                        userRoleGUID: response.json().RoleGUID,
                        lastLogin: response.json().LastLogin,
                        imgName: response.json().ImageName,
                        imgPath: response.json().ImagePath,
                        financialYearID: response.json().CurrentFinancialYearID,
                        roleName: response.json().RoleName,
                        AgentNumber: response.json().AgentNumber,
                        isApprover: response.json().isApprover,
                        isadmin: response.json().isadmin,
                        WAID: response.json().WAID,
                        WAIDGUID: response.json().WAIDGUID,
                        WorkAreaName: response.json().WorkAreaName,
                        WorkareaType: response.json().WorkareaType,
                        loggedIn: new Date(),
                        IvrEnabled: response.json().ivrEnabled,
                        ivrAgentStatus: response.json().ivrAgentStatus,
                        ivrAgentNumber: response.json().ivrAgentNumber,
                        ivrAgentEnabled: response.json().ivrAgentEnabled,
                        ivrAPIURL: response.json().ivrAPIURL,
                        showCircular: true
                    }));
                    return { 'status': true, 'data': response.json() };
                    // return true to indicate successful login
                } else {
                    // return false to indicate failed login
                    return { 'status': false, 'data': '' };
                }
            } else {
                // return false to indicate failed login
                return { 'status': false, 'data': '' };
            }
        });
    }
    PostSignUp(data: any): Observable<any> {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "PostSignUp";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostPasswordPolicy(data: any): Observable<any> {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "PostPasswordPolicy";

        let options = this.getToken();
        return this.PostValueWithoutToastr(this.apiUrl, data, options);
    }
    PostApproveProcess(data: any): Observable<any> {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "PostApproveProcess";

        let options = this.getToken();
        return this.PostValueWithoutToastr(this.apiUrl, data, options);
    }
    getMenuList(): Observable<any> {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "getRoleList?RoleGUID=" + objUser.userRoleGUID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetDashBoardList(): Observable<Boolean> {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "GetDashBoard?RoleGUID=" + objUser.userRoleGUID;

        let options = this.getToken();
        return this.SetValueInStorage(this.apiUrl, options, "UserDashBoardList");
    }
    getUserProfile() {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.employeeApi + "getUserProfile?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetNotification() {
        let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.apiUrl = this.adminApi + "GetNotification?";

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }

    getPMV() {
        this.apiUrl = this.transApi + "GetPMVisit";
        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }

    postPMV() {
        this.apiUrl = this.transApi + "pushEscalationMatrix";
        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }


    PostUploadImage(data: any = {}) {
        this.apiUrl = this.adminApi + "PostUploadImage";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostFileUpload(apiurl, mtd, data: any) {
        this.apiUrl = apiurl + mtd;
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    forgotPassword(email: string): Observable<boolean> {
        this.apiUrl = this.adminApi + "GetForgetPassword?EmailID=" + email + "";
        return this.IsValidRequest(this.apiUrl);
    }
    changePassword(userName: string, oldPassword: string, newPassword: string): Observable<boolean> {
        this.apiUrl = this.adminApi + "GetChangePassword?UserName=" + userName + "&oldPwd=" + oldPassword + "&NewPwd=" + newPassword + "";

        let options = this.getToken();
        return this.IsValidAuthorization(this.apiUrl, options);
    }
    GetDropDownList(filterRef: string, field1: string = "") {
        if (field1 != "")
            this.apiUrl = this.adminApi + "GetDropdown?FilterRef=" + filterRef + "&Depedentfieldvalue=" + field1;
        else
            this.apiUrl = this.adminApi + "GetDropdown?FilterRef=" + filterRef;

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }
    SalesInvoiceGetDropDownList(filterRef: string, field1: string = "", waid) {
        this.apiUrl = this.adminApi + "GetDropdown?FilterRef=" + filterRef + "&Depedentfieldvalue=" + field1 + "&WAIDGUID=" + waid;
        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }
    GetCodeList(filterRef: string, field1: string = "") {
        if (field1 != "")
            this.apiUrl = this.magiCraftApi + "GetCodeList?FilterRef=" + filterRef + "&Depedentfieldvalue=" + field1;
        else
            this.apiUrl = this.magiCraftApi + "GetCodeList?FilterRef=" + filterRef;

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }
    GetDropDownList1(filterRef: string, field1: string = "") {
        if (field1 != "")
            this.apiUrl = this.PMSApi + "GetDropdown?FilterRef=" + filterRef + "&Depedentfieldvalue=" + field1;
        else
            this.apiUrl = this.PMSApi + "GetDropdown?FilterRef=" + filterRef;

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }

    GetCCDropDownList(filterRef: string) {
        this.apiUrl = this.adminApi + "GetCrmCodes?FilterRef=" + filterRef + "";
        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);

    }

    partyvalue(e, ee) {
        let Url = this.adminApi + "GetQuickSearch?FilterRef=" + e + "&SearchValue=" + ee;
        let options = this.getToken();
        return this.GetValueJSON(Url, options);
    }
    /*  GetLocalDataList(filterRef: string) {
         let ccDropDown;
         let Filt = localStorage.getItem('ls' + filterRef);
         if (Filt != null) {
             ccDropDown = localStorage.getItem('ls' + filterRef);
         }
         else {
             this.apiUrl = this.adminApi + "GetCrmCodes?FilterRef=" + filterRef + "";
             let options = this.getToken();
             ccDropDown = this.GetValueWithoutToastr(this.apiUrl, options);
            
             localStorage.setItem('ls' + filterRef, JSON.stringify(ccDropDown));
         }
         return ccDropDown;
     } */

    getMobilename(phone: any) {
        this.apiUrl = this.accountApi + "GetPartyByMobile?MobileNo=" + phone;
        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }

    GetCommonRefVal(DropdownRef: string, InputStr: string) {
        this.apiUrl = this.adminApi + "GetCommonRefVal?DropdownRef=" + DropdownRef + "&InputStr=" + InputStr;

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }
    GetPaySlipList(financialYearID: number) {
        this.apiUrl = this.payrollApi + "GetPayslipList?finanicalyearID=" + financialYearID + "";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetPayslip(monthID: number) {
        this.apiUrl = this.payrollApi + "GetPayslip?MonthID=" + monthID + "";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetTaxslipList(financialYearID: number) {
        this.apiUrl = this.itApi + "GetTaxslipList?FinancialYearID=" + financialYearID + "";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetTaxSlip(monthID: number) {
        this.apiUrl = this.itApi + "GetTaxSlip?MonthID=" + monthID + "";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetCTCBreakUp() {
        this.apiUrl = this.payrollApi + "GetMyCTC";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetYTDPayView() {
        this.apiUrl = this.payrollApi + "GetYTD";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    logout(): Observable<boolean> { // clear token remove user from local storage to log user out        
        this.apiUrl = this.adminApi + "GetSignout?";

        let options = this.getToken();
        return this.IsValidAuthorization(this.apiUrl, options);
    }
    timeout(): Observable<boolean> { // clear token remove user from local storage to log user out        
        this.apiUrl = this.adminApi + "GetTimeout?";

        let options = this.getToken();
        return this.IsValidAuthorization(this.apiUrl, options);
    }
    PostUserCommunicationDetail(data: any = {}) {
        this.apiUrl = this.employeeApi + "PostUserCommunicationDetail";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostUserPersonalDetail(data: any = {}) {
        this.apiUrl = this.employeeApi + "PostUserPersonalDetail";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostLeave(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostLeave";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostLeaveRequest(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostLeaveRequest";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostCompoff(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostCompoff";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostCompOffRequest(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostCompOffRequest";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetAssetRequestList() {
        this.apiUrl = this.assetApi + "GetAssetRequestList";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetAssetData(assetRequestID: number) {
        this.apiUrl = this.assetApi + "GetAssetData?AssetRequestID=" + assetRequestID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetAssetName(assetID: number) {
        this.apiUrl = this.assetApi + "GetAssetName?AssetID=" + assetID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostAssetRequest(data: any = {}) {
        this.apiUrl = this.assetApi + "PostAssetRequest";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetAssetServiceRequest() {
        this.apiUrl = this.assetApi + "GetServiceRequest";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostAssetServiceRequest(data: any = {}) {
        this.apiUrl = this.assetApi + "PostAssetServiceRequest";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetITDeclaration() {
        this.apiUrl = this.itApi + "GetITDeclaration?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostAttendance(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostAttendance";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostManualPunch(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostManualPunch";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostOnDuty(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostOnDuty";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostAttendanceDetails(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostAttendanceDetails";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostITDeclaration(data: any = {}) {
        this.apiUrl = this.itApi + "PostITDeclaration";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetAppraisalList() {
        this.apiUrl = this.appraisalApi + "GetAppraisalList?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetAppraisalData(scheduleGUID: string, employeeScheduleGUID: string) {
        this.apiUrl = this.appraisalApi + "GetAppraisalData?EmployeeScheduleGUID=" + employeeScheduleGUID + "&ScheduleGUID=" + scheduleGUID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostEmployeeAppraisal(data: any = {}) {
        this.apiUrl = this.appraisalApi + "PostEmployeeAppraisal";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetReviewerList() {
        this.apiUrl = this.appraisalApi + "GetReviewerList?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetReviewerData(scheduleGUID: string, employeeScheduleGUID: string, reviewerGUID: string) {
        this.apiUrl = this.appraisalApi + "GetReviewerData?EmployeeScheduleGUID=" + employeeScheduleGUID + "&ScheduleGUID=" + scheduleGUID + "&ReviewerGUID=" + reviewerGUID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostReviewerAppraisal(data: any = {}) {
        this.apiUrl = this.appraisalApi + "PostReviewerAppraisal";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetTrainingFeedBack() {
        this.apiUrl = this.trainingApi + "GetTrainingFeedBackList?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetTrainingFeedBackData(EmpFeedbackGUID: string) {
        this.apiUrl = this.trainingApi + "GetTrainingFeedBackData?EmpFeedbackGUID=" + EmpFeedbackGUID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostEmployeeFeedBack(data: any = {}) {
        this.apiUrl = this.trainingApi + "PostEmployeeFeedBack";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetLoginHistory() {
        this.apiUrl = this.adminApi + "GetLoginHistory?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetDashBoardDetails(apiName: string) {
        this.apiUrl = this.adminApi + apiName + "?";

        let options = this.getToken();
        return this.GetValueWithoutToastr(this.apiUrl, options);
    }
    GetBirthDayList(): Observable<any> {
        this.apiUrl = this.adminApi + "GetBirthday?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetAnniversaryList(): Observable<any> {
        this.apiUrl = this.adminApi + "GetAnniversary?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetLeaveBalanceList(): Observable<any> {
        this.apiUrl = this.adminApi + "GetLeaveBalance?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetApproveRejectCount(): Observable<any> {
        this.apiUrl = this.adminApi + "GetApproveRejectCount?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostEmployeeInOut(data: any = {}) {
        this.apiUrl = this.attendanceApi + "PostEmployeeInOut";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetEmployeeRequestList() {
        this.apiUrl = this.adminApi + "GetEmployeeRequestList?";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    GetRequestedEmployeeList(screenID: string) {
        this.apiUrl = this.adminApi + "GetRequestedEmployeeList?ScreenID=" + screenID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }
    PostXpertsPage(data: any) {
        this.apiUrl = this.adminApi + "PostXpertsPage";

        let options = this.getToken();
        return this.PostValueWithoutToastr(this.apiUrl, data, options);
    }
    PostXpertsResult(data: any) {
        this.apiUrl = this.adminApi + "PostXpertsResult";
        let options = this.getToken();
        return this.PostValueWithoutToastr(this.apiUrl, data, options);
    }

    IVRUpdate(data: any) {
        this.apiUrl = this.getcallagent + "IVRUpdate";
        let options = this.getToken();
        return this.http.post(this.apiUrl, data, options);
    }
    PostRequestedEmployeeDetails(data: any) {
        this.apiUrl = this.adminApi + "PostRequestedEmployeeDetails";

        let options = this.getToken();
        return this.PostValueWithoutToastr(this.apiUrl, data, options);
    }
    GetDashBoardData(dashBoardID: number) {
        this.apiUrl = this.adminApi + "GetDashBoardData?DashboardID=" + dashBoardID;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl, options);
    }

    //// Marketign Module
    GetBlog(data: any) {
        this.apiUrl = this.marketingApi + "GetBlog";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostBlog(data: any) {
        this.apiUrl = this.marketingApi + "PostBlog";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetPromoCate(data: any) {
        this.apiUrl = this.marketingApi + "GetPromoCate";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostPromoCate(data: any) {
        this.apiUrl = this.marketingApi + "PostPromoCate";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetPromo(data: any) {
        this.apiUrl = this.marketingApi + "GetPromo";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostPromo(data: any) {
        this.apiUrl = this.marketingApi + "PostPromo";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }

    ////Admin Module
    GetScreen(data: any) {
        this.apiUrl = this.adminApi + "GetScreen";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostScreen(data: any) {
        this.apiUrl = this.adminApi + "PostScreen";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetRole(data: any) {
        this.apiUrl = this.adminApi + "GetRole";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostRole(data: any) {
        this.apiUrl = this.adminApi + "PostRole";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    PostSurvey(data: any) {
        this.apiUrl = this.grievanceApi + "PostSurvey"
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    // POSTRRF(data: any){
    //     this.apiUrl=this.SalesApi+"POSTRRF"
    //     let options=this.getToken();
    //     return this.PostValueJSON(this.apiUrl,data,options);
    // }
    GetWorkArea(data: any) {
        this.apiUrl = this.adminApi + "GetWorkArea";

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostWorkarea(data: any) {
        this.apiUrl = this.adminApi + "PostWorkarea";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }


    //#endregion
    //#region CRM
    GetPincode(data: any) {
        this.apiUrl = this.masterApi + "GetPincode";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostPincode(data: any) {
        this.apiUrl = this.masterApi + "PostPincode";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }

    GetRoute(data: any) {
        this.apiUrl = this.masterApi + "GetRoute";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostRoute(data: any) {
        this.apiUrl = this.masterApi + "PostRoute";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    /* GetParty(data:any){
        this.apiUrl = this.masterApi + "GetParty";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl+'?'+this.urlParams(data),options);
    }
    PostParty(data: any){
        this.apiUrl = this.masterApi + "PostParty";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    } */

    PostCurrency(data: any) {
        this.apiUrl = this.masterApi + "PostCurrency";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetCurrency(data: any) {
        this.apiUrl = this.masterApi + "GetCurrency";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostCountry(data: any) {
        this.apiUrl = this.masterApi + "PostCountry";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetCountry(data: any) {
        this.apiUrl = this.masterApi + "GetCountry";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostState(data: any) {
        this.apiUrl = this.masterApi + "PostState";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }

    GetState(data: any) {
        this.apiUrl = this.masterApi + "GetState";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }

    PostCity(data: any) {
        this.apiUrl = this.masterApi + "PostCity";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }

    PostGrievanceType(data: any) {
        this.apiUrl = this.grievanceApi + "PostGrievanceType";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    // PostGrievance(data: any) {
    //     this.apiUrl = this.grievanceApi + "PostGrievance";
    //     let options = this.getToken();
    //     return this.PostValueJSON(this.apiUrl, data, options);
    // }
    PostUserProductGroup(data: any) {
        this.apiUrl = this.adminApi + "PostUserProductGroup";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }

    PostBank(data: any) {
        this.apiUrl = this.masterApi + "PostBank";

        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetCity(data: any) {
        this.apiUrl = this.masterApi + "GetCity";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetGrievanceType(data: any) {
        this.apiUrl = this.grievanceApi + "GetGrievanceType";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetUserProductGroup(data: any) {
        this.apiUrl = this.adminApi + "GetUserProductGroup";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    // GetGrievance(data: any) {
    //     this.apiUrl = this.grievanceApi + "GetGrievance";
    //     let options = this.getToken();
    //     return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    // }
    GetSurvey(data: any) {
        this.apiUrl = this.grievanceApi + "GetSurvey";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetBank(data: any) {
        this.apiUrl = this.masterApi + "GetBank";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostItemType(data: any) {
        this.apiUrl = this.masterApi + "PostItemType";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetItemType(data: any) {
        this.apiUrl = this.masterApi + "GetItemType";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostItemCategory(data: any) {
        this.apiUrl = this.masterApi + "PostItemCategory";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetItemCategory(data: any) {
        this.apiUrl = this.masterApi + "GetItemCategory";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostUnits(data: any) {
        this.apiUrl = this.masterApi + "PostUOM";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetUnits(data: any) {
        this.apiUrl = this.masterApi + "GetUOM";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetTaxs(data: any) {
        this.apiUrl = this.masterApi + "GetTax";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetPayTerms(data: any) {
        this.apiUrl = this.masterApi + "GetPayTerm";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetCrmCodebyCode(data: any) {
        this.apiUrl = this.adminApi + "GetCRMCodeByCodeType";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    PostStaticCode(data: any) {
        this.apiUrl = this.adminApi + "PostStaticCode";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    GetStaticCode(data: any) {
        this.apiUrl = this.adminApi + "GetStaticCode";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetStaticCodebyCode(data: any) {
        this.apiUrl = this.adminApi + "GetStaticCodeByCodeType";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    GetItemdetials(data: any) {
        this.apiUrl = this.masterApi + "GetItem?ItemGUID=" + data;
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    ///
    /*   CrmGetJsonService2(apiurl, mtd,data) {
          //let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
          this.apiUrl = apiurl + mtd;
          let options = this.getToken();
          return this.GetValueJSON(this.apiUrl,data options);
      } */
    CrmGetJsonService1(apiurl, mtd, data: any) {
        this.apiUrl = apiurl + mtd;
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    //Origianl APi
    CrmGetJsonService(apiurl, mtd, data: any) {

        this.apiUrl = apiurl + mtd;

        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    CrmPostJsonService(apiurl, mtd, data: any) {
        this.apiUrl = apiurl + mtd;
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }
    placementValidate(apiurl, mtd, data: any) {
        this.apiUrl = apiurl + mtd;
        let options = this.getToken();
        return this.PostValueJSON1(this.apiUrl, data, options);
    }


    PostEmployees(data: any) {
        this.apiUrl = this.masterApi + "PostEmployee";
        let options = this.getToken();
        return this.PostValueJSON(this.apiUrl, data, options);
    }



    //#endregion
    urlParams(request: object): string {
        return Object.keys(request).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(request[k])
        }).join('&');
    }

    GetEmployees(data: any) {
        this.apiUrl = this.masterApi + "GetEmployee";
        let options = this.getToken();
        return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
    }
    //#region User Defined functions
    getToken() {
        let myHeaders = new Headers();
        if (sessionStorage.getItem("currentUser") !== null) {
            let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
            myHeaders.append('Authorization', objUser.token);
        }
        else {
            myHeaders.append('Authorization', "");
        }

        return new RequestOptions({ headers: myHeaders });
    }
    GetValueJSON(url: string, options: any) {
        return this.http.get(url, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111" || response.json().Response._code == "333") {
                    return response.json();
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return null;
                }
                else {
                    //  this.toastr.error("00Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else {
                //this.toastr.error("1Oops!! unable to process your request, Please contact administrator.", 'Error');
                return null;
            }
        });
    }
    GetValueWithoutToastr(url: string, options: any) {
        return this.http.get(url, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111" || response.json().Response._code == "333") {
                    return response.json();
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return null;
                }
                else {
                    //this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else {
                this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                return null;
            }
        });
    }
    PostValueWithoutToastr(url: string, data: any, options: any) {
        return this.http.post(url, data, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111" || response.json().Response._code == "333") {
                    return response.json();
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return null;
                }
                else {
                    //this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else {
                this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                return null;
            }
        });
    }
    PostValueJSON1(url: string, data: any, options: any) {
        return this.http.post(url, data, options).map((response: Response) => {
            if (response.json() != null) {
                if (response.json()._code == "111" || response.json()._code == "333" || response.json()._code == "222" || response.json()._code == "332") {

                    return response.json();
                }
                else if (response.json()._code == "555") {
                    this.onLoggedout();
                    null;
                }
                else {
                    this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else if (response != null) {
                if (response.json()._code == "111" || response.json()._code == "333" || response.json()._code == "222") {

                    return response.json();
                }
                else if (response.json()._code == "555") {
                    this.onLoggedout();
                    null;
                }
            }
            else {
                this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                return null;
            }
        });
    }
    PostValueJSON(url: string, data: any, options: any) {
        return this.http.post(url, data, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111" || response.json().Response._code == "223" || response.json().Response._code == "333" || response.json().Response._code == "222") {

                    return response.json();
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    null;
                }
                else {
                    this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else if (response != null) {
                if (response.json()._code == "111" || response.json()._code == "223" || response.json()._code == "333" || response.json()._code == "222") {

                    return response.json();
                }
                else if (response.json()._code == "555") {
                    this.onLoggedout();
                    null;
                }
            }
            else {
                this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                return null;
            }
        });
    }
    SetValueInStorage(url: string, options: any, storageName: string) {
        return this.http.get(url, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111" || response.json().Response._code == "333") {
                    sessionStorage.setItem(storageName, JSON.stringify(response.json()));
                    return true;
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return false;
                }
                else {
                    this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
                    return response.json();
                }
            }
            else {
                return false;
            }
        });
    }
    IsValidAuthorization(url: string, options: any) {
        return this.http.get(url, options).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111") {
                    return true;
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return false;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        });
    }
    IsValidRequest(url: string) {
        return this.http.get(url).map((response: Response) => {
            if (response.json().Response != null) {
                if (response.json().Response._code == "111") {
                    return true;
                }
                else if (response.json().Response._code == "555") {
                    this.onLoggedout();
                    return false;
                }
                else {
                    return false;
                }
            } else {
                return false;
            }
        });
    }
    onLoggedout() {
        this.logout().subscribe(result => {
            if (result === true) {
            } else {
            }
        });
        this.clearLocalStorage();
        this.router.navigate(['/login/login']);
    }
    onTimeout() {
        this.timeout().subscribe(result => {
            if (result === true) {
            } else {
            }
        });
        this.clearLocalStorage();
        this.router.navigate(['/LockScreen']);
    }
    clearLocalStorage() {
        sessionStorage.clear();
        // sessionStorage.removeItem('profileMenu');
        // sessionStorage.removeItem('selectedMenu');
        // sessionStorage.removeItem('MenuList');
        // sessionStorage.removeItem('currentUser');
        // sessionStorage.removeItem('UserDashBoardList');
    }
    sessionToast(timeOut: number) {
        var sessionToastr: ToastrService;
        // var toastrConfig : ToastrConfig;

        // sessionToastr.toastrConfig.progressBar = true;
        // sessionToastr.toastrConfig.closeButton = true;
        // sessionToastr.toastrConfig.preventDuplicates = true;
        // sessionToastr.toastrConfig.enableHtml = true;
        // sessionToastr.toastrConfig.timeOut = timeOut;
        // sessionToastr.toastrConfig.autoDismiss = true;

        // let toastNotificationConfiguration: ToastNotificationConfiguration = {
        //     message: 'Sample Toast message',
        //     displayDuration: 1000,
        //     autoHide: true,
        //     showCloseButton: true,
        //     toastType: ToastType.INFORMATION
        // };
        /* .options = {
           "closeButton": true,
           "debug": false,
           "newestOnTop": false,
           "progressBar": true,
           "positionClass": "toast-top-right",
           "preventDuplicates": false,
           "onclick": null,
           "showDuration": "300",
           "hideDuration": "1000",
           "timeOut": "5000",
           "extendedTimeOut": "1000",
           "showEasing": "swing",
           "hideEasing": "linear",
           "showMethod": "fadeIn",
           "hideMethod": "fadeOut"
         }
         */

        this.toastr.warning("would you like to continue!! tap me..", 'Session Expiry');
        // <label class='control-label'>would you like to continue!! tap me..</label>
        // this.toastr.show();
        // var toast: Toast = {
        //   type: 'info',
        //   title: 'Here is a Toast Title',
        //   body: 'Here is a Toast Body',
        //   showCloseButton: true,
        //   clickHandler: (t, isClosed): boolean => {
        //   

        //     // got clicked and it was NOT the close button!
        //     if (!isClosed) {

        //     }

        //     return true; // remove this toast !
        //   }
        // };

        // this.toastr. .pop(toast);
    }
    stop() {
        //this.userIdle.stopTimer();
    }
    stopWatching() {
        //this.userIdle.stopWatching();
    }
    startWatching() {
        //this.userIdle.startWatching();
    }
    restart() {
        //this.userIdle.resetTimer();
    }
    getDate(dateSeparator, format) {
        var currentDate = new Date();
        var month = currentDate.getMonth() + 1;
        var day = currentDate.getDate();
        var dateNow;

        if (format != "" && format != null) {
            switch (format) {
                case "dd-MM-yyyy": dateNow = (day < 10 ? '0' : '') + day + dateSeparator + (month < 10 ? '0' : '') + month + dateSeparator + currentDate.getFullYear();
                    break;
                case "yyyy-MM-dd": dateNow = currentDate.getFullYear() + dateSeparator + (month < 10 ? '0' : '') + month + dateSeparator + (day < 10 ? '0' : '') + day;
                    break;

                default: dateNow = currentDate.getFullYear() + dateSeparator + (month < 10 ? '0' : '') + month + dateSeparator + (day < 10 ? '0' : '') + day;
                    break;
            }
        }

        return dateNow;
    }
    GetDateTimeNow(dateSeparator) {
        var currentDate = new Date();
        var month = currentDate.getMonth() + 1;
        var day = currentDate.getDate();
        var dateNow = currentDate.getFullYear() + dateSeparator + (month < 10 ? '0' : '') + month + dateSeparator + (day < 10 ? '0' : '') + day;
        var hr = currentDate.getHours();
        var mi = currentDate.getMinutes();
        var sec = currentDate.getSeconds();
        var timeNow = (hr < 10 ? '0' : '') + hr + ":" + (mi < 10 ? '0' : '') + mi + ":" + (sec < 10 ? '0' : '') + sec;
        return dateNow + " " + timeNow;
    }
    formatDate(date) {

        // var monthNames = [
        //   "January", "February", "March",
        //   "April", "May", "June", "July",
        //   "August", "September", "October",
        //   "November", "December"
        // ];
        if (date != null && date != '') {
            date = new Date(date);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
        } else {
            return date;
        }
    }
    DateDiff(fromdate, toDate) {
        var d1 = new Date(fromdate);
        var d2 = new Date(toDate);
        var timeDiff = d2.getTime() - d1.getTime();
        var DaysCalc = timeDiff / (1000 * 60 * 60 * 24);
        console.log(timeDiff);

        console.log(DaysCalc);

        return DaysCalc;
    }

    daysDiffer(fromdate, toDate) {
        var d1 = new Date(fromdate);
        var d2 = new Date(toDate);
        //var timeDiff = d2.getTime() - d1.getTime();    
        return Math.round((fromdate - toDate) / (1000 * 60 * 60 * 24));
    }
    //#endregion

    dateFormat(date, divider) {
        if (date != null && date != undefined && date != '') {
            var d = [];
            d = date.split(divider);

            var day = d[0];
            var month = d[1];
            var year = d[2];
            return year + '-' + month + '-' + day;
        }
        else
            return '';
    }
    dateFormatNew(e: any) {
        if (e != '' && e != null && e != undefined) {
            let d = new Date(e);
            let month = d.getMonth() + 1;
            let date = d.getDate();
            let dates = d.getFullYear() + '-' + (month.toString().length == 1 ? '0' + month : month) + '-' + (date.toString().length == 1 ? '0' + date : date);
            console.log(dates);
            return dates;
        }
        else {
            return "";
        }
    }
    dateFormatWithTime(e: any, option) {
        console.log(e);

        if (e != '' && e != null && e != undefined) {
            let d = new Date(e);
            let month = d.getMonth() + 1;
            let date = d.getDate();
            let hour = (d.getHours()).toString();
            let minute = (d.getMinutes()).toString();
            let sec = (d.getSeconds()).toString();
            if (option == 'date') {
                let dates = d.getFullYear() + '-' + (month.toString().length == 1 ? '0' + month : month) + '-' + (date.toString().length == 1 ? '0' + date : date);
                console.log(dates);
                return dates;
            } else if (option == 'dateAndTime') {
                let dates = d.getFullYear() + '-' + (month.toString().length == 1 ? '0' + month : month) + '-' + (date.toString().length == 1 ? '0' + date : date) + 'T' + (hour.length == 1 ? '0' + hour : hour) + ':' + (minute.length == 1 ? '0' + minute : minute) + ':' + (sec.length == 1 ? '0' + sec : sec) + 'Z';
                return dates;
            }
        }
        else {
            return "";
        }
    }
    dateFormat_1(date, divider) {

        if (date != null && date != undefined && date != '') {
            var d = [];
            d = date.split(divider);

            var day = d[0];
            var month = d[1];
            var year = d[2];
            return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
        }
        else
            return '';
    }
    YYMMDDtoDDMMYY(date, type) {
        let currentdate = new Date(date);

        var day = currentdate.getDate();
        var month = currentdate.getMonth() + 1;
        var year = currentdate.getFullYear();

        if (type == 1) {
            return (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
        }
        if (type == 2) {
            return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
        }
    }

    //#region  Input field Validations
    public inputNumeric(e: any) {
        return (e.which == 8 || e.which == 0 || e.which == 13 || e.which == 46 || (e.which >= 48 && e.which <= 57)) ? true : false;
    }

    public _restrictNumbers(event: any) {
        const pattern = /^[0-9]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }

    public _restrictNumberswithDecimal(event: any) {
        const pattern = /^[0-9.]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }
    public _allowNumbersWithMinus(event: any) {
        const pattern = /^[0-9- .]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }
    public _restrictCharacters(event: any) {
        const pattern = /^[A-Za-z ]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }
    public _alphaCharacters(event: any) {
        const pattern = /^[A-Za-z0-9 ]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }

    public _restrictspecialchar(event: any) {
        const pattern = /^[a-zA-Z0-9,./_ &+$-]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }
    public emiratesIDFormat(event: any) {
        const pattern = /^[0-9-]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            if (event.keyCode == 8 || event.keyCode == 9)
                console.log("backspace");
            else
                event.preventDefault();
        }
    }



    //#endregion 
    lastLoginFormat(inputDate, dateSeparator) {
        var monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        var date = new Date(inputDate);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var dateNow = (day < 10 ? '0' : '') + day + dateSeparator + monthNames[month] + dateSeparator + year;
        var time = date.getHours() + ":" + date.getMinutes();

        return dateNow + ' ' + time;
    }

    /*    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
           return next.handle(request).pipe(
               retry(1),
               catchError((error: HttpErrorResponse) => {
                   if (error.status === 401) {
                       // refresh token
                   } else {
                       return throwError(error);
                   }
               })
           );
       } */

    /*     handleError(error) {
            // your custom error handling logic    
          }
    
          //All Type Of Error Handling
    
          getClientMessage(error: Error): string {
            if (!navigator.onLine) {
                return 'No Internet Connection';
            }
            return error.message ? error.message : error.toString();
        }
    
        getClientStack(error: Error): string {
            return error.stack;
        }
    
        getServerMessage(error: HttpErrorResponse): string {
            return error.message;
        }
    
        getServerStack(error: HttpErrorResponse): string {
            // handle stack trace
            return 'stack';
        } */
    date: Date = new Date();
    tmaxDate: Date = new Date(this.date.getDate() + 10);


}