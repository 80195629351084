import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../services/authentication.service';
import { NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-popup-upload',
  templateUrl: './popup-upload.component.html',
  styleUrls: ['./popup-upload.component.scss']
})
export class PopupUploadComponent {
  model: any = { 'FileData': "", 'Desc': "Master Upload" };
  FileUploadData: any = { 'base64': "", 'name': "", 'size': "" };
  DownloadLink: string = "";
  errorLog: any;
  fileSizeLimit: number = 3;
  mb: number = 1048576;
  pageData: any = {};

  sumitChanges: boolean = false;
  commonSave: string = 'Upload';
  icon: string = "fa fa-upload";
  succesMeasge: string;
  Icon: string
  currentUser:any;
  constructor(private authService: AuthenticationService,
    private toastr: ToastrService, public dialogRef: MatDialogRef<PopupUploadComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pageData = JSON.parse(sessionStorage.getItem('selectedMenu'));
    this.fileSizeLimit = this.calculateSize(this.authService.env.uploadFileSize);
    this.DownloadLink = this.authService.apiService + this.data.DownloadLink;
    

    //this.errorLog = this.data.errorLog;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }
  calculateSize(val) {
    if (val == undefined || typeof (val) != 'number')
      return 1048576;
    return val * this.mb;
  }
  close() {
    this.dialogRef.close();
  }
  downloadLink() {

  }
  postForm(frm: NgForm) {
    let req = {
      "FileName": this.FileUploadData.name,
      "base64String": this.FileUploadData.base64,
      "FilePath": "",
      "PageName": this.pageData.MenuName,
      "PrimaryKeyGUID": UUID.UUID(),
      "PageGUID": this.pageData.PageGUID,
      "FileSizeInBytes": this.FileUploadData.size,
      "FileGUID": UUID.UUID(),
      "IPAddress": "",
      "FileMode": "post",
      "Description": this.model.Desc,
      "WAIDGUID": this.currentUser.WAIDGUID,
      "LoginGUID": this.currentUser.userGUID,
      "UploadFileList": [],
      "UploadType": 'Master'
    }

    this.sumitChanges = true;
    this.commonSave = 'Processing';
    this.icon = "fa fa-spinner fa-spin";

    this.succesMeasge = 'Uploading is in progressing';
    this.Icon = "fa fa-circle-o-notch fa-spin";
    this.authService.CrmPostJsonService(this.authService.adminApi, 'PostFileHandling', req).subscribe(result => {
      if (result !== null) {
        this.sumitChanges = false;
        this.commonSave = 'Upload';
        this.icon = "fa fa-upload";
        if (result.Response._code == "111") {
          //this.toastr.success('File Uploaded Successfully', 'success');
          this.succesMeasge = 'Files Uploded Successfully';
          this.Icon = "fa fa-check";
          // this.dialogRef.close();
        }
        else {
          if (result.Response._code == "333") { //fa fa-check // fa fa-ban //fa fa-warning
            this.errorLog = result.ErrorlogFile; //this.authService.apiService + '/Log/UploadLog' +
            this.succesMeasge = 'File Uploaded failed please Check errorLog File';
            this.Icon = "fa fa-exclamation-triangle";
          }
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }
  base64(file) {
    var myReader: FileReader = new FileReader();
    var _this = this;
    myReader.onloadend = function (loadEvent: any) {
      _this.FileUploadData.base64 = loadEvent.target.result.split(",")[1];
      _this.FileUploadData.name = file.name;
      _this.FileUploadData.size = file.size;
    };
    if (file)
      myReader.readAsDataURL(file);
  }
  fileUpload(e) {
    if (e.target.files[0].size < this.fileSizeLimit)
      this.base64(e.target.files[0]);
    else {
      this.model.FileData = "";
      this.toastr.error('Please upload file size less than' + (this.fileSizeLimit / this.mb).toString() + 'mb')
    }
  }
  clear(frm: NgForm) {
    frm.resetForm();
  }
}
