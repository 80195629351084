import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-call-cancel-request',
  templateUrl: './call-cancel-request.component.html',
  styleUrls: ['./call-cancel-request.component.scss']
})
export class CallCancelRequestComponent implements OnInit {
CancelRequestData: any = [];
  constructor(private authService: AuthenticationService, private toastr: ToastrService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    
      this.authService.CrmGetJsonService(this.authService.callsApi, 'GetCallCancelRequestList', {}).subscribe(result => {
        if (result !== null) {
          if (result.Response._code == "111") {
            this.CancelRequestData = result.CancelRequest;

            //  sessionStorage.setItem('CancelRequestData', JSON.stringify(this.CancelRequestData))
          } else {
            //this.toastr.error(result.Response._Description, 'Error');
          }
        }
      });
  


  }
  approve(index, type) {
    if (type == 1) {
      var req = { 'CallGuid': this.CancelRequestData[index].CallGuid, 'RequestType': 'Approved' };
    }
    if (type == 2) {
      var req = { 'CallGuid': this.CancelRequestData[index].CallGuid, 'RequestType': 'Rejected' };
    }
    this.authService.CrmPostJsonService(this.authService.callsApi, 'PostCallRequestUpdate', req).subscribe(result => {

      if (result !== null) {
        if (result.Response._code == "111") {
          this.CancelRequestData.splice(index, 1);
          this.toastr.success(result.Response._Description, "Success");
        } else {
          this.toastr.error(result.Response._Description, "Error");
        }
      }
    });

  }

}
