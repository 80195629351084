import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from "ngx-bootstrap";
// import { Ng2SmartTableModule } from 'ng2-smart-table';
//app root pages
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing'; // Routing Module
import { LoginComponent } from './login/login.component';
import { Page404Component } from './page-404/page-404.component';
import { Page500Component } from './page-500/page-500.component';
// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { CallCancelRequestComponent } from './layouts/call-cancel-request/call-cancel-request.component';

import { NAV_DROPDOWN_DIRECTIVES } from './shared/nav-dropdown.directive';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { AsideToggleDirective } from './shared/aside.directive';
import { BreadcrumbsComponent } from './shared/breadcrumb.component';

// Service Module 
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthGuard } from './shared/guards/index';
import { MaintanceGuard } from './shared/guards/maintance.guard'
import { AuthenticationService, UserService, ValidationService } from './shared/services/index';
import { MockBackend, MockConnection } from '@angular/http/testing';
import { BaseRequestOptions } from '@angular/http';
import { FormsModule } from '@angular/forms';


// chart library
import { ChartsModule } from 'ng2-charts/ng2-charts';
// geo Map library
import { AgmCoreModule } from '@agm/core';
// http service loding bar
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
//calendar 
import { CalendarModule } from 'angular-calendar';
//import { CalendarHeaderModule } from './shared/calendar-header/calendar-header.module';
//import { CalendarHeaderComponent } from './shared/calendar-header/calendar-header.component';

import { LoadingModule } from 'ngx-loading';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// Tansalator
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Toaster
import { ToastrModule } from 'ngx-toastr';

//Page Service
import { PagerService } from './shared/pagenation/index';

// User Idle 
//import { UserIdleModule } from 'angular-user-idle';
import { XpertsPageModule } from './xperts-page/xperts-page.module';
import { AttachmentPopupModule } from './attachment-popup/attachment-popup.module';
import { PopupWorkareaComponent } from './shared/component/popup-workarea/popup-workarea.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { MaintanceComponent } from './maintance/maintance.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { CommonPopupComponent } from './shared/component/common-popup/common-popup.component';
import { CircularAlertComponent } from './circular-alert/circular-alert.component';
// import { GrievancepopupComponent } from './master/grievancepopup/grievancepopup.component';

import { MatDialogModule } from '@angular/material/dialog';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { DirectiveModule } from './shared/Directive/directive.module';
import { DialogconfirmComponent } from './shared/dialogconfirm/dialogconfirm.component';
import { ConfirmationPopupComponent } from './shared/component/confirmation-popup/confirmation-popup.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingComponent } from './setting/setting.component';
import { PurchaseBranchChangeComponent } from './shared/purchase-branch-change/purchase-branch-change.component';

import { DistribuorPopupComponent } from './shared/distribuor-popup/distribuor-popup.component';
import { ImageVerificationPopupComponent } from './shared/image-verification-popup/image-verification-popup.component';

import { PurchasePvupdatePopupComponent } from './shared/purchase-pvupdate-popup/purchase-pvupdate-popup.component';
import { ApprovedPopupComponent } from './shared/approved-popup/approved-popup.component';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatCommonModule, MatDatepickerModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatLineModule, MatListModule, MatMenuModule, MatNativeDateModule, MatOptionModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatPseudoCheckboxModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, NativeDateModule } from '@angular/material';
import { AddressPopupComponent } from './admin/address-popup/address-popup.component';
import { SelfPickupPopupComponent } from './shared/self-pickup-popup/self-pickup-popup.component';
// import {FilterkeyPipe} from './shared/pipes/filterkey.pipe';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

//firbase authentication
const environment = {
  apiKey: "AIzaSyC6DAisy5AvQZ1_uHi4b64naA4XKMbuC1M",
  authDomain: "venus-aa6a8.firebaseapp.com",
  databaseURL: "https://venus-aa6a8.firebaseio.com",
  projectId: "venus-aa6a8",
  storageBucket: "",
  messagingSenderId: "971310194585",
  appId: "1:971310194585:web:c777215bfc366d1a"
};

@NgModule({
  imports: [
    // Ng2SmartTableModule,
    MatDialogModule,
    NgbDatepickerModule.forRoot(),
    AngularFireModule.initializeApp(environment),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,
    NgSelectModule,

    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    AlertModule.forRoot(),
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    NgbModule,
    NgbModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: ' AIzaSyDUtnxXGPX4U4qtD4CYRakny-I_TIbTqUk '
    }),
    CalendarModule.forRoot(),
    LoadingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    LoadingBarHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    XpertsPageModule,
    AttachmentPopupModule,
    PipesModule,
    DirectiveModule,
    MatButtonModule, MatCheckboxModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule, MatSidenavModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatRadioModule,
    MatOptionModule, MatAutocompleteModule, MatButtonToggleModule, MatChipsModule, MatCommonModule,
    MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule,
    MatLineModule, MatListModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
    MatProgressSpinnerModule, MatPseudoCheckboxModule, MatRippleModule,
    MatSelectModule, MatSliderModule, MatSlideToggleModule,
    MatSnackBarModule, MatSortModule, MatStepperModule,
    MatTableModule, MatTabsModule, NativeDateModule,



    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    //UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 120})
  ],
  declarations: [

    AppComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    LoginComponent,
    Page404Component,
    Page500Component,
    PopupWorkareaComponent,
    MaintanceComponent,
    LockScreenComponent,
    CommonPopupComponent,
    CircularAlertComponent,
    // GrievancepopupComponent,
    CallCancelRequestComponent,
    DialogconfirmComponent,
    ConfirmationPopupComponent,
    SettingComponent,
    PurchaseBranchChangeComponent, DistribuorPopupComponent, ImageVerificationPopupComponent, PurchasePvupdatePopupComponent, ApprovedPopupComponent,
    SelfPickupPopupComponent
    // FilterkeyPipe,
    //FranchisevisitComponent,
    //DeletePopupComponent,
    //DialogwinParse2Component
  ],
  entryComponents: [PurchaseBranchChangeComponent, ImageVerificationPopupComponent, DistribuorPopupComponent, PopupWorkareaComponent, CommonPopupComponent, CircularAlertComponent, SettingComponent, DialogconfirmComponent, ConfirmationPopupComponent, PurchasePvupdatePopupComponent, ApprovedPopupComponent, SelfPickupPopupComponent],
  providers: [
    AuthGuard, MaintanceGuard,
    AuthenticationService,
    ValidationService,
    DatePipe,

    UserService,

    { provide: LocationStrategy, useClass: HashLocationStrategy },
    PagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
