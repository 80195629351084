import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { ToastrService } from "ngx-toastr";
import { NgForm, NG_VALIDATORS, Validator, FormControl } from "@angular/forms";

import { DatePipe } from "@angular/common";
const PAGE_MODE_SEARCH = 0;
const RESPONSE_SUCCESS = 111;
const RESPONSE_NODATA = 222;
const RESPONSE_FAILED = 333;

@Component({
  selector: "app-dialogconfirm",
  templateUrl: "./dialogconfirm.component.html",
  styleUrls: ["./dialogconfirm.component.scss"],
})
export class DialogconfirmComponent {
  remarks: string;
  xpertsPageMode: number;
  ProgressSpinner: boolean;
  titlechange: boolean = false;
  value: any;
  error: boolean = false;
  Remarks: any;
  methodName: string;
  ActivityOn: Date = new Date();
  icon: string = "fa fa-check";
  minimumDate: Date;
  maximumDate: Date;
  hidedate: boolean;
  hidedatereject: boolean = false;
  user: any;
  Log: any;
  ApprovedNotes: any;
  userName: any;

  constructor(
    private toastr: ToastrService,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<DialogconfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe
  ) {
    dialogRef.disableClose = true;
    let d = JSON.parse(sessionStorage.getItem("LoginDetials"));
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log("this.user", user);
    if (d.isdev == "True" || d.isApprover == "True") {
      this.hidedate = true;
    } else {
      this.hidedate = false;
    }
  }
  pageMode(mode) {
    this.xpertsPageMode = mode;
    console.log("mode", mode);
  }
  ngOnInit() {
    console.log(this.data.type);
    console.log("Data", this.data);
    console.log("this.ActivityOn12");

    var mindatetemp = new Date(this.ActivityOn);
    // var maxdatetemp = new Date(this.ActivityOn);
    mindatetemp.setDate(mindatetemp.getDate() - 3);
    // maxdatetemp.setDate(maxdatetemp.getDate()+3);
    this.minimumDate = new Date(mindatetemp);
    // this.maximumDate = new Date(maxdatetemp)
    console.log("minimumDate", this.minimumDate);
  }
  close() {
    this.dialogRef.close();
  }
  postForm(): void {
    console.log("data....", this.data.input[0]);
    if (this.data.input[0].Reference == "PurchaseRequest") {
      console.log("hi");
      this.icon = "fa fa-spinner fa-spin";
      var user = JSON.parse(sessionStorage.getItem("currentUser"));
      this.userName = user.userName;
      let request = {
        ActivityBy: user.UserGUID,
        ActivityStatusName: "Approved",
        ActivityOn: this.ActivityOn,
        // ActivityOn: new Date(),
        ActivityRemarks: this.remarks,
        ActivityStatus: this.data.input[0].status,
        MagPoints: 0,
        OrderID: this.data.input[0].ResponseValue.PRNO,
        PaidAmount: this.data.input[0].ResponseValue.PRNetAmount,
        Paymode: 0,
        RecordKey: this.data.input[0].value,
        TransFrom: this.data.input[0].transform,
        TransOnlineRemarks: "By Mag Points",
        TransOnlineStatus: "TXN_SUCCESS",
        TransRefNo: "Manually Approved",
        DistGUID: "",
        ActivityFrom: "BO",
        distId: this.data.input[0].ResponseValue.DistributorID,
      };

      if (this.remarks) {
        this.ProgressSpinner = true;
        console.log("ActivityOn", request.ActivityOn);
        let ActivityOnDateandTimeMag = this.datePipe.transform(
          new Date(request.ActivityOn),
          "yyyy-MM-dd h:mm"
          // let ActivityOnDateandTimeMag = this.datePipe.transform(
          //   new Date(request.ActivityOn),
          //   "yyyy-MM-dd h:mm"
        );
        console.log("test", ActivityOnDateandTimeMag);
        request.ActivityRemarks = request.ActivityRemarks.concat(
          ",",
          ActivityOnDateandTimeMag.toString(),
          " by ",
          this.userName.toString()
        );
        request.TransOnlineRemarks = request.ActivityRemarks;
        // this.request.ActivityOn = this.authService.formatDate(this.request.PurchaseDate);
        request.ActivityOn = this.authService.formatDate(request.ActivityOn);
        this.authService
          .CrmPostJsonService(
            this.authService.SalesApi,
            "PostTransActivity",
            request
          )
          .subscribe((result) => {
            this.icon = "fa fa-check";
            this.ProgressSpinner = false;
            if (result !== null) {
              if (result.Response._code == RESPONSE_SUCCESS) {
                this.toastr.success(result.Response._Description, "Success");
                this.dialogRef.close("Success");
              } else {
                this.toastr.error(result.Response._Description, "Error");
                this.ProgressSpinner = false;
              }
            }
          });
      } else {
        this.ProgressSpinner = false;
        this.error = true;
      }
    } else if (this.data.input[0].transform == "MLR") {
      console.log("hi");
      this.icon = "fa fa-spinner fa-spin";
      var user = JSON.parse(sessionStorage.getItem("currentUser"));
      this.userName = user.userName;
      let request = {
        ActivityBy: user.UserGUID,
        ActivityStatusName: "Approved",
        ActivityOn: new Date(),
        ActivityRemarks: this.remarks,
        ActivityStatus: this.data.input[0].status,
        MagPoints: 0,
        OrderID: "",
        PaidAmount: "",
        Paymode: 0,
        RecordKey: this.data.input[0].value,
        TransFrom: this.data.input[0].transform,
        TransOnlineRemarks: "By Mag Points",
        TransOnlineStatus: "TXN_SUCCESS",
        TransRefNo: "Manually Approved",
        DistGUID: "",
        ActivityFrom: "BO",
        distId: "",
      };

      if (this.remarks) {
        this.ProgressSpinner = true;
        let ActivityOnDateandTimeMag = this.datePipe.transform(
          new Date(request.ActivityOn),
          "yyyy-MM-dd h:mm"
        );
        console.log("test", ActivityOnDateandTimeMag);
        request.ActivityRemarks = request.ActivityRemarks.concat(
          ",",
          ActivityOnDateandTimeMag.toString(),
          " by ",
          this.userName.toString()
        );
        request.TransOnlineRemarks = request.ActivityRemarks;
        // this.request.ActivityOn = this.authService.formatDate(this.request.PurchaseDate);
        request.ActivityOn = this.authService.formatDate(request.ActivityOn);
        this.authService
          .CrmPostJsonService(
            this.authService.SalesApi,
            "PostTransActivity",
            request
          )
          .subscribe((result) => {
            this.icon = "fa fa-check";
            this.ProgressSpinner = false;
            if (result !== null) {
              if (result.Response._code == RESPONSE_SUCCESS) {
                this.toastr.success(result.Response._Description, "Success");
                this.dialogRef.close("Success");
              } else {
                this.toastr.error(result.Response._Description, "Error");
                this.ProgressSpinner = false;
              }
            }
          });
      } else {
        this.ProgressSpinner = false;
        this.error = true;
      }
    } else {
      console.log("data....", this.data.input[0].McpGetData);
      console.log("hi12");
      this.icon = "fa fa-spinner fa-spin";
      var user = JSON.parse(sessionStorage.getItem("currentUser"));
      this.userName = user.userName;
      let request = {
        ActivityBy: user.UserGUID,
        ActivityStatusName: "Approved",
        ActivityOn: new Date(),
        ActivityRemarks: this.remarks,
        ActivityStatus: this.data.input[0].status,
        MagPoints: 0,
        OrderID: this.data.input[0].McpGetData.PRNO,
        PaidAmount: this.data.input[0].McpGetData.PRNetAmount,
        Paymode: 0,
        RecordKey: this.data.input[0].value,
        TransFrom: this.data.input[0].transform,
        TransOnlineRemarks: "By Mag Points",
        TransOnlineStatus: "TXN_SUCCESS",
        TransRefNo: "Manually Approved",
        DistGUID: this.data.input[0].McpGetData.PRWAGUID,
        ActivityFrom: "BO",
        distId: "",
      };
      // this.data.input[0].McpGetData.PRWAGUID,
      if (this.remarks) {
        this.ProgressSpinner = true;
        let ActivityOnDateandTimeMag = this.datePipe.transform(
          new Date(request.ActivityOn),
          "yyyy-MM-dd h:mm"
        );
        console.log("test", ActivityOnDateandTimeMag);
        request.ActivityRemarks = request.ActivityRemarks.concat(
          ",",
          ActivityOnDateandTimeMag.toString(),
          " by ",
          this.userName.toString()
        );
        request.TransOnlineRemarks = request.ActivityRemarks;
        // this.request.ActivityOn = this.authService.formatDate(this.request.PurchaseDate);
        request.ActivityOn = this.authService.formatDate(request.ActivityOn);
        this.authService
          .CrmPostJsonService(
            this.authService.SalesApi,
            "PostTransActivity",
            request
          )
          .subscribe((result) => {
            this.icon = "fa fa-check";
            this.ProgressSpinner = false;
            if (result !== null) {
              if (result.Response._code == RESPONSE_SUCCESS) {
                this.toastr.success(result.Response._Description, "Success");
                this.dialogRef.close("Success");
              } else {
                this.toastr.error(result.Response._Description, "Error");
                this.ProgressSpinner = false;
              }
            }
          });
      } else {
        this.ProgressSpinner = false;
        this.error = true;
      }
    }
  }

  confirm() {
    let req;
    if (this.data.type == "Confirmation") {
      console.log("PostPurchaseRequestCancel");
      req = {
        PRGUID: this.data.GUID,
        Remarks: this.Remarks,
      };
      this.methodName = "PostPurchaseRequestCancel";
    }
    if (this.data.type == "SalesConfirm") {
      req = {
        SIGUID: this.data.GUID,
        Remarks: this.Remarks,
      };
      this.methodName = "PostSalesinvoiceCancel";
    }
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    this.authService
      .CrmPostJsonService(this.authService.SalesApi, this.methodName, req)
      .subscribe((res) => {
        this.icon = "fa fa-check";
        this.ProgressSpinner = false;
        let result: any = res;
        if (result != null) {
          if (result.Response._code == "111") {
            this.dialogRef.close("Success");
            this.toastr.success("Cancellation Successfully", "Suceess");
          } else {
            this.toastr.warning(result.Response._Description);
          }
        }
      });
  }
  confirmVerifiedImage() {
    let req;
    console.log('imagedata', this.data);
    req = {
      "RefCode": "PRVerificationProcess",
      "RefKey": this.data.model.MagnessaID,
      "RefValue": "1",
      "RefValue1": this.data.model.Imagetype,
      "RefValue2": "",
      "RefValue3": ""
    };
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    this.authService
      .CrmPostJsonService(this.authService.adminApi, 'PostCommonUpdate', req)
      .subscribe((res) => {
        this.icon = "fa fa-check";
        this.ProgressSpinner = false;
        let result: any = res;
        if (result != null) {
          if (result.Response._code == "111") {
            this.dialogRef.close("Success");
            this.toastr.success(result.Response._Description, "Suceess");
          } else {
            this.toastr.warning(result.Response._Description);
          }
        }
      });
  }

  updateconfirm(formPage: NgForm) {
    console.log("this.Remarks", this.Remarks);
    this.dialogRef.close({ result: "Success", UpdateRemarks: this.Remarks });
    //formPage.resetForm();
  }
  // ReceivedConfirm(formPage: NgForm) {
  //   console.log("this.Remarks", this.Remarks);
  //   this.dialogRef.close({ result: 'Success', ReceivedRemarks: this.Remarks });

  // }
  ReceivedConfirm() {
    let req;
    if (this.data.type == "ReceivedConfirm") {
      req = {
        InvoiceGUID: this.data.GUID,
        ReceivedRemarks: this.Remarks,
      };
      this.methodName = "PostSalesInvoiceReceivedUpdate";
    }

    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    this.authService
      .CrmPostJsonService(this.authService.SalesApi, this.methodName, req)
      .subscribe((res) => {
        this.icon = "fa fa-check";
        this.ProgressSpinner = false;
        let result: any = res;
        if (result != null) {
          if (result._code == RESPONSE_SUCCESS) {
            this.dialogRef.close("Success");
            console.log("testsearchpage");
            this.xpertsPageMode = PAGE_MODE_SEARCH;
            // this.toastr.success('Cancellation Successfully', 'Suceess');
          } else {
            this.toastr.warning(result.Response._Description);
          }
        }
      });
  }

  WalletConfirm(form: NgForm) {
    let Log = JSON.parse(sessionStorage.getItem("LoginDetials"));
    let req;
    if (this.data.type == "WalletConfirm") {
      req = Object.assign({}, this.data.value);
      console.log("this.req", req);
      this.methodName = "PostWallet";
      req.DistID = this.data.DistID;
      req.ApproverGUID = Log.UserGUID;
      req.ApprovalRemarks = this.remarks;
      req.UserGUID = Log.UserGUID;
      req.Status = this.data.input[0].status;
      console.log("this.req.status", req.Status);
    }

    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    console.log("this.req.ApproverGuid", req.ApproverGUID);
    console.log("this.req.ApproverGuid", Log.UserGUID);
    this.authService
      .CrmPostJsonService(this.authService.WalletApi, this.methodName, req)
      .subscribe((res) => {
        this.icon = "fa fa-check";
        this.ProgressSpinner = false;
        let result: any = res;
        if (result != null) {
          if (result._code == "111") {
            this.dialogRef.close("Success");
            console.log("testsearchpage");
            this.xpertsPageMode = PAGE_MODE_SEARCH;
            // this.toastr.success('Cancellation Successfully', 'Suceess');
          } else {
            this.toastr.warning(result._Description);
          }
        }
      });
  }

  McpPayment() {
    let req;
    if (this.data.type == "McpPayment") {
      req = Object.assign({}, this.data.model);
      console.log("this.req", req);
      req.ApprovedNotes = this.ApprovedNotes;
      this.methodName = "PostMCPPayment";
    }
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    this.authService
      .CrmPostJsonService(this.authService.adminApi, this.methodName, req)
      .subscribe((res) => {
        this.icon = "fa fa-check";
        this.ProgressSpinner = false;
        let result: any = res;
        if (result != null) {
          if (result.Response._code == "111") {
            this.close();
            this.xpertsPageMode = PAGE_MODE_SEARCH;
            this.toastr.success(result.Response._Description);
          } else if (result.Response._code == "222") {
            this.toastr.warning(result.Response._Description);
          } else {
            this.toastr.error(result.Response._Description);
          }
        }
      });
  }
}
