import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DdIndexValuePipe } from './dd-index-value.pipe';
import { ArrayPairPipe } from './array-pair.pipe';
import { QuickSearchPipe } from './quick-search.pipe';
import { FilterkeyPipe } from './filterkey.pipe';
import {CustommaxDirective} from '../Directive/custommax.directive';
import {CustomminDirective} from '../Directive/custommin.directive';
import{CurrencyInputMaskComponent} from '../Directive/currency-input-mask/currency-input-mask.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CurrencyInputMaskComponent,CustommaxDirective,CustomminDirective,DdIndexValuePipe, ArrayPairPipe, QuickSearchPipe,FilterkeyPipe],  
  exports:[CurrencyInputMaskComponent,CustommaxDirective,CustomminDirective,DdIndexValuePipe,ArrayPairPipe,QuickSearchPipe,FilterkeyPipe]  
})
export class PipesModule {  }
export {ArrayPairPipe};
