import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-verification-popup',
  templateUrl: './image-verification-popup.component.html',
  styleUrls: ['./image-verification-popup.component.scss']
})
export class ImageVerificationPopupComponent {

  constructor(private toastr: ToastrService, private authService: AuthenticationService, public dialogRef: MatDialogRef<ImageVerificationPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    
  }
  close(){
    this.dialogRef.close();
  }
}
