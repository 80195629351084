/**------------------------------------------------------------------------------------
 * PROGRAM NAME :Setting
 * PURPOSE :Setting filter  
 * ------------------------------------------------------------------------------------
 * DEVELOPER NAME : Mohamed asik.s                            DEVELOPED ON :18-04-2020 
 * REVIEWER NAME :                                            REVIEWED ON : 
 * ------------------------------------------------------------------------------------
 * HISTORY
 * ------------------------------------------------------------------------------------
 * DATE AND TIME | MODIFIED BY |   CHANGES
 * ------------------------------------------------------------------------------------
 * 20-04-2020    |Mohamed asik | added table edit,delete and update logic 
 * 21-04-2020    |Mohamed asik | altered field design 
 * ---------------------------------------------------------------------------------------*/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  pageGUID: any;
  pageData: any;
  pageFilter: any;
  pageResult: any = [];
  model: any;
  allowedit: any = -1;
  resultallowedit: any = -1;
  fileTypeList: any;
  controlTypeList: any;
  filterDisabledList: any;
  resultTypeList: any;
  showError: boolean;
  tempArray: any;
  resultTempArray: any;
  resultShowError: boolean
  url: any;
  decoded: string;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthenticationService, private toastr: ToastrService) { }


  ngOnInit() {
    this.loadDropDown();
    this.ngForm();
    this.route.queryParams.subscribe(params => {//getting id from full-layout page
      this.pageGUID = params.pageGUID
      let url = params.url;
      this.url = url.split('#');
    });
    this.getSetting();
  }
  back() {
    this.router.navigate([decodeURIComponent(this.url[1])]);
  }
  ngForm() {
    this.model = {
      "PageName": "",
      "StoredProcedureName": "",
      "TableName": "",
      "RowCnt": '',
      "PageType": '',
      "ReportViewStoredProcedure": "",
      "ExportEnabled": '',
      "ExportSp": "",
      "PrintViewEnabled": '',
      "PrintConfig": "",
      "UploadTemplate": "",
      "uploadEnabled": "",
      "ColsPerRow": '',
      "IsRowSelectRequired": '',
      "PrintviewColumnEnabled": '',
      "Status": 1,
      "SendEmailEnabled": '',
      "PageFilters": [],
      "PageResultLists": []
    }

  }
  filterForm() {
    let filterForm = {
      "FilterName": "",
      "FilterLabel": "",
      "FilterType": "",
      "Sequence": "",
      "FilterRef": "",
      "IsMandatory": "",
      "ControlType": "",
      "IsDisabled": "",
      "DefaultValue": "",
      "DefaultLabel": "",
      "Status": "",
      "WaterMark": "",
    }
    this.pageFilter.unshift(filterForm);
    console.log(this.pageFilter);
  }
  resultForm() {
    let resultForm = {
      "ResultName": "",
      "ResultProcedureName": "",
      "ResultType": "",
      "IsNavigate": "",
      "IsVisible": "",
      "Sequence": "",
      "Status": "",
      "DefaultLabel": "",
      "cellcolor": "",
    }
    this.pageResult.unshift(resultForm);
    console.log(this.pageResult);
  }

  loadDropDown() {
    this.authService.GetCodeList('FilterType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.fileTypeList = result.Dropdownlist;


        }
      }
    });
    this.authService.GetCodeList('ControlType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.controlTypeList = result.Dropdownlist;
        }
      }
    });
    this.authService.GetCodeList('FilterDisabled').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.filterDisabledList = result.Dropdownlist;
        }
      }
    });
    this.authService.GetCodeList('ResultType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.resultTypeList = result.Dropdownlist;
        }
      }
    });
  }


  mandatory(e) {
    console.log(e);

  }

  getSetting() {
    let req = { 'PageGUID': this.pageGUID }
    this.authService.CrmGetJsonService(this.authService.magiCraftApi, 'GetXpertsPage', req).subscribe(result => {
      this.pageData = result.XpertsPages;
      this.model = Object.assign({}, this.pageData[0]);
      //for filter screen
      console.log(this.model.PageFilters);

      this.pageFilter = this.pageData[0].PageFilters;

      //for result screen
      this.pageResult = this.pageData[0].PageResultLists;
      if (this.model.ColsPerRow == 1) {
        this.model.ColsPerRow = true;
      } else {
        this.model.ColsPerRow = false;
      }

    });
  }
  postForm(form: any) {
    if (this.model.PageType == true) {
      this.model.PageType = 1
    } else {
      this.model.PageType = 0
    }
    if (this.model.ExportEnabled == true) {
      this.model.ExportEnabled = 1
    } else {
      this.model.ExportEnabled = 0
    }
    if (this.model.PrintViewEnabled == true) {
      this.model.PrintViewEnabled = 1
    } else {
      this.model.PrintViewEnabled = 0
    }
    if (this.model.ColsPerRow == true) {
      this.model.ColsPerRow = 1

    } else {
      this.model.ColsPerRow = 2
    }
    if (this.model.IsRowSelectRequired == true) {
      this.model.IsRowSelectRequired = 1
    } else {
      this.model.IsRowSelectRequired = 0
    }
    if (this.model.uploadEnabled == true) {
      this.model.uploadEnabled = 1
    } else {
      this.model.uploadEnabled = 0
    }
    if (this.model.PrintviewColumnEnabled == true) {
      this.model.PrintviewColumnEnabled = 1
    } else {
      this.model.PrintviewColumnEnabled = 0
    }
    if (this.model.Status == true) {
      this.model.Status = 1
    } else {
      this.model.Status = 0
    }
    //pagefilter parameter
    for (let index in this.pageFilter) {
      if (this.pageFilter[index].IsMandatory == true) {
        this.pageFilter[index].IsMandatory = 1;
      } else {
        this.pageFilter[index].IsMandatory = 0;
      }
      if (this.pageFilter[index].Status == true) {
        this.pageFilter[index].Status = 1;
      } else {
        this.pageFilter[index].Status = 0;
      }
    }
    //pageresult parameter
    for (let index in this.pageResult) {
      if (this.pageResult[index].IsNavigate == true) {
        this.pageResult[index].IsNavigate = 1
      } else {
        this.pageResult[index].IsNavigate = 0
      }
      if (this.pageResult[index].IsVisible == true) {
        this.pageResult[index].IsVisible = 1
      } else {
        this.pageResult[index].IsVisible = 0
      }
      if (this.pageResult[index].Status == true) {
        this.pageResult[index].Status = 1
      } else {
        this.pageResult[index].Status = 0
      }
    }
    this.model.PageFilters = this.pageFilter;
    this.model.PageResultLists = this.pageResult;
    this.authService.CrmPostJsonService(this.authService.magiCraftApi, 'PostXpertsPage', this.model).subscribe(result => {
      if (result !== null) {
        if (result.Response._code == 111) {
          this.toastr.success(result.Response._Description, 'Success');
          form.reset();
          this.getSetting();
        } else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }
  temp() {
    this.tempArray = {
      "FilterName": "",
      "FilterLabel": "",
      "FilterType": "",
      "Sequence": "",
      "FilterRef": "",
      "IsMandatory": "",
      "ControlType": "",
      "IsDisabled": "",
      "DefaultValue": "",
      "DefaultLabel": "",
      "Status": "",
      "WaterMark": "",
    }
  }

  //Filter Screen Function
 
  delete(loop, index) {
    this.pageFilter.splice(index, 1);
  }
  save(loop, index) {
    console.log(loop.Sequence);

    if (this.pageFilter[index].FilterName == '' || this.pageFilter[index].FilterLabel == '' || this.pageFilter[index].FilterType == undefined || this.pageFilter[index].Sequence == ''
      || this.pageFilter[index].Sequence > 100 || this.pageFilter[index].DefaultLabel == '' || this.pageFilter[index].ControlType == undefined || this.pageFilter[index].IsDisabled == undefined) {
      this.showError = true
    } else {
      this.allowedit = -1;
      this.pageFilter.splice(index, 1, loop);
      //for control type field
      let idx = this.controlTypeList.findIndex(array => array.ID == loop.ControlType);
        if (idx != -1) {
          this.pageFilter[index].ControlTypeName = this.controlTypeList[idx].Value
        }
      //ends
      //for filter type field
      let idx2 = this.fileTypeList.findIndex(array => array.ID == loop.FilterType);
      if (idx2 != -1) {
        this.pageFilter[index].FilterTypeName = this.fileTypeList[idx2].Value
      }
      //ends
      //for disable type field
      let idx3 = this.filterDisabledList.findIndex(array => array.ID == loop.IsDisabled);
      if (idx3 != -1) {
        this.pageFilter[index].ISDisabledName = this.filterDisabledList[idx3].Value
      }
      //ends
    }
  }
  add() {
    this.tempArray = '';
    this.allowedit = 0;
    this.filterForm();
  }
  edit(index, loop) {
    this.allowedit = index
    this.tempArray = Object.assign({}, loop);

  }
  undo(index) {
    console.log(this.tempArray);
    
    if (this.tempArray != '') {
      console.log(this.tempArray);
      this.pageFilter.splice(index, 1, this.tempArray);
      this.allowedit = -1;
    }else{
    this.pageFilter.splice(index, 1)
    this.allowedit = -1;
    }
  }

  addResultRow() {
    this.resultallowedit = 0;
    this.resultForm();
  }
  //result screen function
  resultedit(index, loop) {
    this.resultallowedit = index;
    this.resultTempArray = Object.assign({}, loop);
    }
  resultsave(loop, index) {
    if (loop.ResultName == '' || loop.ResultProcedureName == '' || loop.ResultType == undefined || loop.Sequence == '' || loop.Sequence > 100) {
      this.resultShowError = true;
    } else {
      this.resultallowedit = -1;
      this.pageResult.splice(index, 1, loop);
      //for control type field
      let idx = this.resultTypeList.findIndex(array => array.ID == loop.ResultType);
        if (idx != -1) {
          this.pageResult[index].ResultTypeName = this.resultTypeList[idx].Value
        }
      //ends
    }
  }
  resultdelete(index) {
    this.pageResult.splice(index, 1);
  }
  resultUndo(index) {
    console.log(this.resultTempArray);
    this.pageResult.splice(index, 1, this.resultTempArray);
    this.resultallowedit = -1
  }
}
