import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Injectable()
export class MaintanceGuard implements CanActivate {
  public env: any = environment;
  constructor(private router: Router) { }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.env.maintenance) {
      this.router.navigate(['/Maintenance']);
      return false;
    }
    return true;
    
  }
}
